import { configureStore } from '@reduxjs/toolkit'
import iaamSlice from './iaamSlice'
import projectSlice from './projectSlice'
import drgMgtSlice from './drgMgtSlice'
import subjectFileSlice from './subjectFileSlice'
import openRightSideSlice from './openRightSideSlice'
import objectFileSlice from './objectFileSlice'
import dpeConfigSlice from './dpeConfigSlice'
import attributeSelectionSlice from './attributeSelectionSlice'
import regulatoryFileSlice from './regulatoryFileSlice'

export const store = configureStore({
  reducer: {
    iaamContext: iaamSlice,
    project: projectSlice,
    drgMgt: drgMgtSlice,
    subjectFile: subjectFileSlice,
    openRightSide: openRightSideSlice,
    objectFile: objectFileSlice,
    dpeConfig: dpeConfigSlice,
    attributeSelection: attributeSelectionSlice,
    regulatoryFile: regulatoryFileSlice
  }, 
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
        serializableCheck: false,
    })
})