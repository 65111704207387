import React, { Fragment, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Button, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, Stack, Checkbox, FormControlLabel, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getDpeConfig } from '../../../../../redux/dpeConfigSlice';
import { useSelector } from 'react-redux';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function CreateConditionDialog(props) {

    const theme = useTheme();
    const { open, setOpen, nodeSelected, setNodes, nodes } = props;
    // const dpeConfig = useSelector(getDpeConfig);
    const [resourceAttribute, setResourceAttribute] = useState([]);
    const [resourceAttributeSelected, setResourceAttributeSelected] = useState(null);
    const [operator, setOperator] = useState('');
    const [value, setValue] = useState("");
    const [resource, setResource] = useState("");
    const [subjectAttribute, setSubjectAttribute] = useState([]);
    const [subjectAttributeSelected, setSubjectAttributeSelected] = useState(null);
    const [checked, setChecked] = useState(false); //Test
    const [dpeConfig, setDpeConfig] = useState();
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        const dpeConfig = JSON.parse(localStorage.getItem('dpe-config'));
        if (dpeConfig) {
            setDpeConfig(dpeConfig);
        }
    }, []);

    const handleCheckboxChange = (event) => { //Test
        if (event.target.checked) {
            handleGetSubjectAttribute();
            setSubjectAttributeSelected(null);
            setValue("");
        } else {
            setSubjectAttributeSelected(null);
            setValue("");
        }
        setChecked(event.target.checked);
    };

    const handleChange = (event) => {
        setOperator(event.target.value);
    };

    const handleClose = () => {
        setResourceAttribute([]);
        setResourceAttributeSelected(null);
        setChecked(false);
        setSubjectAttribute([])
        setSubjectAttributeSelected(null)
        setOperator('');
        setValue();
        setOpen(false);
    };

    const handleCreate = () => {
        let condition;
        let condition_display;
        let expression;
        let right_value;

        if (checked) {
            expression = {
                l_operand: {
                    object_attr: resourceAttributeSelected
                },
                operator: operator,
                r_operand: {
                    subject_attr: `request.subject.${subjectAttributeSelected}`
                }
            };
            condition_display = `${resourceAttributeSelected} ${operator} request.subject.${subjectAttributeSelected}`;
            right_value = subjectAttributeSelected;
        } else {
            let data_type_value;
            const data_type = resourceAttribute.map(attr => {
                if (attr.name === resourceAttributeSelected) {
                    if (attr.data_type === 'text' || attr.data_type === 'character' || attr.data_type === 'character varying') {
                        return data_type_value = value.toString();
                    } else if (attr.data_type === 'boolean') {
                        return data_type_value = Boolean(value);
                    } else if (attr.data_type === 'double precision' || attr.data_type === 'integer' || attr.data_type === 'bigint') {
                        return data_type_value = Number(value);
                    } else if (attr.data_type === 'ARRAY') {
                        return data_type_value = [String(value)];
                    } else {
                        return null;
                    }
                }
            })
            expression = {
                l_operand: {
                    object_attr: resourceAttributeSelected
                },
                operator: operator,
                r_operand: {
                    const: data_type_value
                }
            };
            condition_display = `${resourceAttributeSelected} ${operator} ${value}`;
            right_value = value;
        }

        condition = {
            display: condition_display,
            resourceAttribute: resourceAttributeSelected,
            operator: operator,
            value: right_value,
            checked: checked
        };

        const conditions_all = nodeSelected.data.general_properties.conditions;
        let conditions = conditions_all.map((cond) => cond);
        conditions.push(condition);

        setNodes((nds) => nds.map((node) => {
            if (node.id === nodeSelected.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        general_properties: {
                            ...node.data.general_properties,
                            conditions: conditions,
                            expression: expression
                        },
                    }
                };
            }
            return node;
        }));
        handleClose();
    };

    const checkResource = () => {
        const root_node = nodes.filter((node) => {
            return node.type === 'root'
        });
        if (root_node.length > 0) {
            if (root_node[0].data.general_properties.resource !== undefined) {
                setResource(root_node[0].data.general_properties.resource.resource_label);
            } else {
                setResource("");
            }
        }
    };

    const getResourceAttributeList = async () => {
        // const iaam = window.iaam;
        // const accessToken = await iaam.getAccessToken();
        // let option = {
        //     method: 'GET',
        //     headers: {
        //         'Content-type': 'application/json',
        //         'Authorization': `bearer ${accessToken}`
        //     }
        // }
        // const response = await fetch(`${dpeConfig.resource_schema_endpoint}?resource=${resource}`, option);
        // if (response.ok) {
        //     const result = await response.json();
        //     setResourceAttribute(result);
        // } else {
        //     console.error(response);
        // }

        const root_node = nodes.find((node) => node.type === "root");
        if (root_node?.data?.general_properties?.attribute_selections) {
            setResourceAttribute(root_node.data.general_properties.attribute_selections);
        } else {
            setResourceAttribute([]);
        }

    };

    const handleGetSubjectAttribute = async () => {
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                scopeName: "profile"
            })
        };

        const response = await fetch('https://' + process.env.REACT_APP_OP_HOST + '/iap-api/get-user-attributes', requestOptions);
        if (response.status === 200) {
            const result = await response.json();
            const arrayOfObjects = Object.entries(result).map(([key, value]) => {
                return { label: key, ...value };
            });
            setSubjectAttribute(arrayOfObjects)
        } else {
            console.error(response.status);
        }
    };

    useEffect(() => {
        if (open) {
            checkResource();
        }
    }, [open])

    // useEffect(() => {
    //     if (resource) {
    //         getResourceAttributeList();
    //     }
    // }, [resource])

    useEffect(() => {
        if (resourceAttributeSelected && operator && (subjectAttributeSelected || value)) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }, [resourceAttributeSelected, operator, subjectAttributeSelected, value])

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth
                maxWidth="md"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 2, borderTopRightRadius: 2 }} id="customized-dialog-title">
                    <div style={{ width: '100%' }}>
                        <Typography variant='h6' color={theme.palette.white.main}>Create Condition</Typography>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: theme.palette.white.main
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>

                    <div style={{ padding: '10px' }}>

                        <Typography variant="caption" display="block" gutterBottom>Resource</Typography>
                        <Typography variant='subtitle1' mt={-1} mb={2}>{resource}</Typography>

                        <Box>
                            <Grid container spacing={2} alignItems="flex-end">
                                <Grid item xs={4}>
                                    <Box
                                        onClick={() => {
                                            getResourceAttributeList();
                                        }}>
                                        <Autocomplete
                                            id="combo-box"
                                            size='small'
                                            fullWidth
                                            onInputChange={(event, newInputValue) => {
                                                setResourceAttributeSelected(newInputValue);
                                            }}
                                            options={resourceAttribute}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            renderInput={(params) => <TextField {...params} label="Resource Attribute" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel
                                            id="select-autowidth-label"
                                        >Operator</InputLabel>
                                        <Select
                                            labelId="select-autowidth-label"
                                            id="select-autowidth"
                                            label="Operator"
                                            value={operator}
                                            onChange={handleChange}
                                            autoWidth
                                            sx={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <MenuItem value="=">Equal (=)</MenuItem>
                                            <MenuItem value="!=">Not Equal (!=)</MenuItem>
                                            <MenuItem value=">">Greater than (&gt;)</MenuItem>
                                            <MenuItem value="<">Less than (&lt;)</MenuItem>
                                            <MenuItem value=">=">Greater than or equal to (&gt;=)</MenuItem>
                                            <MenuItem value="<=">Less than or equal to (&lt;=)</MenuItem>
                                            <MenuItem value="like">Like</MenuItem>
                                            <MenuItem value="in">In</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checked}
                                                        onChange={handleCheckboxChange}
                                                        color="primary"
                                                    />
                                                }
                                                label="Subject Value"
                                            />
                                        </Grid>
                                        <Grid item>
                                            {checked ?
                                                <Box>
                                                    <Autocomplete
                                                        id="combo-box"
                                                        size='small'
                                                        fullWidth
                                                        onInputChange={(event, newInputValue) => {
                                                            setSubjectAttributeSelected(newInputValue);
                                                        }}
                                                        options={subjectAttribute}
                                                        getOptionLabel={(option) => option.label}
                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        renderInput={(params) => <TextField {...params} label="Subject Value" />}
                                                    />
                                                </Box> :
                                                <TextField
                                                    id="value"
                                                    label="Value"
                                                    fullWidth
                                                    size='small'
                                                    value={value}
                                                    onChange={(e) => setValue(e.target.value)}
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <Button
                                        sx={{ height: '40px' }}
                                        variant="contained"
                                        size="large"
                                        startIcon={<AddIcon />}
                                        onClick={() => { }}
                                    >
                                        Add
                                    </Button>
                                </Grid>
                                <Grid item xs={1}>
                                    {/* <IconButton
                                        style={{
                                            // display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CloseIcon className='text-gray-500 hover:text-blue-600' />
                                    </IconButton> */}
                                </Grid>
                            </Grid>
                        </Box>

                    </div>

                </DialogContent>
                <DialogActions>
                    <Box sx={{ m: 0, paddingX: 1, paddingY: 1 }}>
                        <Button variant="contained" disabled={disabled} onClick={() => handleCreate()}>Create</Button>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </Fragment>
    )
}

export default CreateConditionDialog