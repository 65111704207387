import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getProfile } from '../../../../../redux/iaamSlice';
import { getOpenRightSide, setOpenRightSide } from '../../../../../redux/openRightSideSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    ReactFlow,
    addEdge,
    MiniMap,
    Controls,
    Background,
    ControlButton,
    useReactFlow,
    MarkerType,
    applyNodeChanges,
    applyEdgeChanges,
    reconnectEdge,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import { Backdrop, CircularProgress, Drawer } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import RegulatoryRightSide from './RegulatoryRightSide';
import { getRegulatoryFile } from '../../../../../redux/regulatoryFileSlice';
import SubjectRegulatoryNode from './SubjectRegulatoryNode';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import ConditionRegulatoryNode from './ConditionRegulatoryNode';
import CreateSubjectConstraintDialog from '../../Subject/components/CreateSubjectConstraintDialog';
import SelectResourceDialog from '../../Object/components/SelectResourceDialog';
import AttributeSelection from '../../Object/components/AttributeSelection';
import CreateConditionDialog from '../../Object/components/CreateConditionDialog';
import GetAppIcon from '@mui/icons-material/GetApp';
import SelectionRegulatoryNode from './SelectionRegulatoryNode';
import ConjunctionRegulatoryNode from './ConjunctionRegulatoryNode';
import PrivacyRegulatoryNode from './PrivacyRegulatoryNode';
import RootRegulatoryNode from './RootRegulatoryNode';

const nodeTypes = {
    root: RootRegulatoryNode,
    condition: ConditionRegulatoryNode,
    selection: SelectionRegulatoryNode,
    privacy: PrivacyRegulatoryNode,
    conjunction: ConjunctionRegulatoryNode,
    subject: SubjectRegulatoryNode
};

function RegulatoryDiagram() {

    // const profile = useSelector(getProfile);
    // const profile = JSON.parse(localStorage.getItem('iaam'));
    const dispatch = useDispatch();
    const openRightSide = useSelector(getOpenRightSide);
    const regulatoryFile = useSelector(getRegulatoryFile);
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [nodeSelected, setNodeSelected] = useState();
    const [openProperties, setOpenProperties] = useState(false);
    const { screenToFlowPosition, setCenter } = useReactFlow();
    const [profile, setProfile] = useState();

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
    }, []);

    //Subject
    const [openCreateSubjectConstraint, setOpenCreateSubjectConstraint] = useState(false);

    //Object
    const [openSelectResource, setOpenSelectResource] = useState(false);
    const [openAttributeSelection, setOpenAttributeSelection] = useState(false);
    const [openCreateCondition, setOpenCreateCondition] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        zoomTo,
        getViewport,
        getNodes,
        getEdges,
        setViewport,
        fitView
    } = useReactFlow();

    // const [initialized, setInitialized] = useState(false);

    const drawerWidth = 300;

    const saveViewportState = useCallback(() => {
        const viewport = getViewport();
        const all_nodes = getNodes();
        const all_edges = getEdges();
        const viewport_flow = {
            nodes: all_nodes,
            edges: all_edges,
            viewport: viewport
        }
        const allFlows = JSON.parse(localStorage.getItem('regulatory-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('regulatory-file')) || {};
        allFlows[currentFile.regulatory_diagram_id] = viewport_flow;
        localStorage.setItem('regulatory-diagram', JSON.stringify(allFlows));
    }, [getViewport, getNodes, getEdges]);

    const restoreFlowState = (node_data, edge_data) => {
        const allFlows = JSON.parse(localStorage.getItem('regulatory-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('regulatory-file')) || {};
        const flowState = allFlows[currentFile.regulatory_diagram_id];
        if (flowState) {
            setTimeout(() => {
                setNodes(node_data);
                setEdges(edge_data);
            }, 300);
            setTimeout(() => {
                setViewport(flowState.viewport || { x: 0, y: 0, zoom: 1 });
                setLoading(false);
            }, 500);
        } else {
            setTimeout(() => {
                setNodes(node_data);
                setEdges(edge_data);
            }, 300);
            setTimeout(() => {
                fitView();
                zoomTo(1, { duration: 0 });
                setLoading(false);
            }, 500);
        }
    };

    useEffect(() => {
        if (regulatoryFile) {
            setNodeSelected(undefined);
            if (regulatoryFile.data) {
                setLoading(true);
                restoreFlowState(regulatoryFile.data.nodes, regulatoryFile.data.edges);
            } else {
                setLoading(true);
                restoreFlowState([], []);
            }
        }
    }, [regulatoryFile])

    // useEffect(() => {
    //     if (!initialized) {
    //         setTimeout(() => {
    //             zoomTo(1, { duration: 0 });
    //         }, 100);
    //         setInitialized(true);
    //     }
    // }, [initialized]);

    const onPaneClick = () => {
        setOpenProperties(false);
    };

    const onNodeClick = (event, node) => {
        setNodeSelected(node);
        setOpenProperties(true);
    };

    const nodesWithUpdatedData = useMemo(() => {
        return nodes.map((node) => {
            if (node.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        isSelected: node.id === nodeSelected?.id,
                    },
                };
            }
            return node;
        });
    }, [nodes, nodeSelected]);

    const updatePosition = async (result, position) => {

        const newNodes = await result.data.nodes.map(node => ({
            ...node,
            position: {
                x: node.position.x + position.x,
                y: node.position.y + position.y,
                zoom: 1
            },
        }));

        const newEdges = await result.data.edges.map(edge => ({
            ...edge,
            source: newNodes.find(n => n.id === edge.source)?.id || edge.source,
            target: newNodes.find(n => n.id === edge.target)?.id || edge.target,
        }));

        return { nodes: newNodes, edges: newEdges };

    };

    const fetchData = async (type, id, position) => {
        if (type === "subject") {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/subject-diagram/get-subject/${id}`);
            if (response.ok) {
                const result = await response.json();
                return updatePosition(result, position);
            } else {
                console.error('Error fetching data');
                return { nodes: [], edges: [] };
            }
        } else if (type === "object") {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/object-diagram/get-object/${id}`);
            if (response.ok) {
                const result = await response.json();
                return updatePosition(result, position);
            } else {
                console.error('Error fetching data');
                return { nodes: [], edges: [] };
            }
        }
    };

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        async (event) => {
            event.preventDefault();

            const nodeData = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (typeof nodeData === 'undefined' || !nodeData) {
                return;
            }

            const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            const positionView = {
                x: event.clientX,
                y: event.clientY,
            };

            const objectData = JSON.parse(nodeData);
            const { nodes: newNodes, edges: newEdges } = await fetchData(objectData.type, objectData.diagram_id, position);

            if (newNodes.length > 0) {
                setNodes((nds) => nds.concat(newNodes));
                setEdges((eds) => eds.concat(newEdges));
                setViewport(positionView.x, positionView.y, { zoom: 1, duration: 800 });
            }

        },
        [screenToFlowPosition],
    );

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge({
            ...params,
            style: { stroke: 'red', strokeWidth: 1 },
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: 'red',
            },
        }, eds)),
        [setEdges],
    );

    const handleSaveDiagram = async () => {
        const now = Date.now();
        let update = {
            regulatory_diagram_id: regulatoryFile.regulatory_diagram_id,
            regulatory_diagram_name: regulatoryFile.regulatory_diagram_name,
            creator_id: regulatoryFile.creator_id,
            creator_name: regulatoryFile.creator_name,
            last_update_id: profile.uprofile.inum,
            last_update_name: profile.uprofile.displayName,
            create_timestamp: regulatoryFile.create_timestamp,
            update_timestamp: now,
            description: regulatoryFile.description,
            complete: true,
            data: {
                nodes: nodes,
                edges: edges
            }
        }

        console.log("update", JSON.stringify(update, undefined, 2))

        const requestOptions = {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(update)
        };

        const response = await fetch(process.env.REACT_APP_API_URL + `/regulatory-diagram/update-regulatory/${regulatoryFile.regulatory_diagram_id}`, requestOptions);
        if (response.status === 200) {
            const result = await response.json();
            alert('Regulatory diagram updated successfully');
        } else {
            console.error(response.status);
            alert('Regulatory diagram updated failed');
        }
    };

    const handleClearDiagram = () => {
        setNodes([]);
        setEdges([]);
    };

    useEffect(() => {
        if (nodes.length !== 0) {
            nodes.map((node) => {
                if (node.id === nodeSelected?.id) {
                    let update_current_node = {
                        ...node,
                        data: {
                            ...node.data,
                        },
                    };
                    setNodeSelected(update_current_node);
                }
            });
        }
    }, [nodes])

    const onNodesChange = useCallback(
        async (changes) => {
            setNodes((nodes) => {
                const oldNodes = nodes.map(
                    (node) => ({
                        ...node,
                        measured: {
                            width: node.measured?.width || 150,
                            height: node.measured?.height || 40,
                        }
                    })
                );
                return applyNodeChanges(changes, oldNodes);
            });
        },
        [setNodes],
    );

    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges],
    );

    const onNodeDoubleClick = useCallback(() => {
        if (openRightSide) {
            dispatch(setOpenRightSide(openRightSide));
        } else {
            dispatch(setOpenRightSide(!openRightSide));
        }
    }, [nodes, edges]);

    const onConnectStart = () => {
        setNodes(nodes => nodes.map(node => ({ ...node, data: { ...node.data, showHandle: true } })));
    };

    const onConnectEnd = (event) => {
        setNodes(nodes => nodes.map(node => ({ ...node, data: { ...node.data, showHandle: false } })));
        if (!event.target.closest('.react-flow__handle')) {
            console.log('Connection was not made.');
        }
    };

    // const onReconnect = useCallback((oldEdge, newConnection) => {
    //     setEdges((els) => reconnectEdge(oldEdge, newConnection, els))
    // }, []);

    const handleReconnect = (oldEdge, newConnection) => {
        console.log('Reconnect oldEdge:', oldEdge);
        console.log('Reconnect newConnection:', newConnection);
        // console.log('oldEdge', oldEdge)
        // console.log('newConnection', newConnection)
        // setEdges((els) => reconnectEdge(oldEdge, newConnection, els))
    };

    const handleExportDiagram = () => {
        createRegulationGraph(regulatoryFile.regulatory_diagram_id);
    };

    const createRegulationGraph = async (regulatory_id) => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/regulatory-diagram/get-regulatory/${regulatory_id}`);
        const dataJson = await response.json();

        const regulation_graph = {
            subject_graph: {
                nodes: {},
                edges: {}
            },
            regulatory: {},
            object_graph: {
                nodes: {},
                edges: {}
            }
        }

        const regulationData = dataJson.data;
        const nodeData = regulationData.nodes;
        const edgeData = regulationData.edges;

        for (let index = 0; index < nodeData.length; index++) {
            const element = nodeData[index];
            if (element.type === 'subject') {
                if (element.root) {
                    regulation_graph.subject_graph.nodes[element.id] = {
                        label: element.data.general_properties.label,
                        root: true
                    }
                } else {
                    regulation_graph.subject_graph.nodes[element.id] = {
                        label: element.data.general_properties.label,
                        expression: element.data.general_properties.expression
                    }
                }
            } else {
                let params = {};
                if (element.type === "root") {
                    params = {
                        resource: element.data.general_properties.resource.resource_label
                    }
                } else if (element.type === "condition") {
                    if (element.data.general_properties.conditions.length > 0) {
                        params = {
                            expression: element.data.general_properties.expression
                        }
                    }
                } else if (element.type === "privacy") {
                    let privacys = element.data.general_properties.privacys;
                    for (let index = 0; index < privacys.length; index++) {
                        privacys[index]["attribute"] = privacys[index].attribute.name;
                    }
                    params = {
                        privacy_aspect: privacys
                    }
                } else if (element.type === "selection") {
                    params = {
                        selection: element.data.general_properties.selection
                    }
                }
                regulation_graph.object_graph.nodes[element.id] = {
                    type: element.type,
                    label: element.data.general_properties.label,
                    ...params
                }
            }
        }

        for (let index = 0; index < edgeData.length; index++) {
            const element = edgeData[index];
            const source = element.source;
            const target = element.target;
            let sourceType = "object";
            let targetType = "object";
            if (regulation_graph.subject_graph.nodes[source] !== undefined) {
                sourceType = "subject";
            }
            if (regulation_graph.subject_graph.nodes[target] !== undefined) {
                targetType = "subject";
            }
            if (sourceType === targetType) {
                if (sourceType === "subject") {
                    if (regulation_graph.subject_graph.edges[source] === undefined) {
                        regulation_graph.subject_graph.edges[source] = {
                            to: [{
                                node: target
                            }]
                        }
                    } else {
                        regulation_graph.subject_graph.edges[source].to.push({
                            node: target
                        })
                    }
                } else {
                    if (regulation_graph.object_graph.edges[source] === undefined) {
                        regulation_graph.object_graph.edges[source] = {
                            to: [{
                                node: target
                            }]
                        }
                    } else {
                        regulation_graph.object_graph.edges[source].to.push({
                            node: target
                        })
                    }
                }
            } else {
                if (regulation_graph.regulatory[source] === undefined) {
                    regulation_graph.regulatory[source] = {
                        association: [{
                            node: target
                        }]
                    }
                } else {
                    regulation_graph.regulatory[source].association.push({
                        node: target
                    })
                }
            }
        }

        // writefile downloadfile senttoMongoDB
        const jsonBlob = new Blob([JSON.stringify(regulation_graph, null, 2)], { type: 'application/json' });

        // สร้างลิงก์ URL สำหรับดาวน์โหลดไฟล์
        const url = URL.createObjectURL(jsonBlob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${regulatoryFile.regulatory_diagram_name}.json`;
        link.click();

        // ล้าง URL หลังจากการดาวน์โหลดเสร็จสิ้น
        URL.revokeObjectURL(url);

        console.log("Download File Success");

    }

    return (
        <div className='h-[calc(100vh-64px)] position-relative'>
            {regulatoryFile &&
                <>
                    <ReactFlow
                        nodes={nodesWithUpdatedData}
                        edges={edges}
                        nodeTypes={nodeTypes}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onNodeClick={onNodeClick}
                        onPaneClick={onPaneClick}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onMoveEnd={saveViewportState}
                        onConnectStart={onConnectStart}
                        onConnectEnd={onConnectEnd}
                        onNodeDoubleClick={onNodeDoubleClick}
                        // onReconnect={onReconnect}
                        // fitView
                        connectionLineStyle={{ stroke: 'red', strokeWidth: 1 }}
                    >
                        <MiniMap style={{ position: 'absolute', bottom: 0, left: 0, backgroundColor: 'none', width: 200 }} />
                        <Controls position="top-left">
                            <ControlButton onClick={() => handleSaveDiagram()}>
                                <SaveIcon />
                            </ControlButton>
                            <ControlButton onClick={() => handleClearDiagram()}>
                                <LayersClearIcon />
                            </ControlButton>
                            <ControlButton onClick={() => handleExportDiagram()}>
                                <GetAppIcon />
                            </ControlButton>
                        </Controls>
                        <Background />
                    </ReactFlow>
                    <Drawer
                        open={openRightSide}
                        anchor="right"
                        variant="persistent"
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginTop: "52px" },
                        }}
                    >
                        <RegulatoryRightSide
                            nodeSelected={nodeSelected}
                            nodes={nodes}
                            setNodes={setNodes}
                            open={openProperties}
                            setOpen={setOpenProperties}
                            setOpenCreateSubjectConstraint={setOpenCreateSubjectConstraint}
                            setOpenSelectResource={setOpenSelectResource}
                            setOpenCreateCondition={setOpenCreateCondition}
                            setOpenAttributeSelection={setOpenAttributeSelection}
                        />
                    </Drawer>
                    <CreateSubjectConstraintDialog
                        open={openCreateSubjectConstraint}
                        setOpen={setOpenCreateSubjectConstraint}
                        setNodes={setNodes}
                        nodeSelected={nodeSelected}
                    />
                    <SelectResourceDialog
                        open={openSelectResource}
                        setOpen={setOpenSelectResource}
                        nodeSelected={nodeSelected}
                        setNodes={setNodes}
                    />
                    <CreateConditionDialog
                        open={openCreateCondition}
                        setOpen={setOpenCreateCondition}
                        nodeSelected={nodeSelected}
                        nodes={nodes}
                        setNodes={setNodes}
                    />
                    <AttributeSelection
                        open={openAttributeSelection}
                        setOpen={setOpenAttributeSelection}
                    />
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: 1,
                            backgroundColor: 'rgba(220, 220, 220, 1)',
                        }}
                        open={loading}
                        onClick={() => setLoading(false)}
                    >
                        <CircularProgress />
                    </Backdrop>
                </>
            }
        </div>
    )
}

export default RegulatoryDiagram