import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getProfile } from '../../../../../redux/iaamSlice';
import { getOpenRightSide, setOpenRightSide } from '../../../../../redux/openRightSideSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    ReactFlow,
    addEdge,
    MiniMap,
    Controls,
    Background,
    ControlButton,
    useReactFlow,
    MarkerType,
    applyNodeChanges,
    applyEdgeChanges,
    reconnectEdge,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import { Backdrop, CircularProgress, Drawer } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { getObjectFile } from '../../../../../redux/objectFileSlice';
import RootNode from './RootNode';
import ObjectRightSide from './ObjectRightSide';
import SelectResourceDialog from './SelectResourceDialog';
import ConditionNode from './ConditionNode';
import PrivacyNode from './PrivacyNode';
import ConjunctionNode from './ConjunctionNode';
import CreateConditionDialog from './CreateConditionDialog';
import AttributeSelection from './AttributeSelection';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import SelectionNode from './SelectionNode';

const nodeTypes = {
    root: RootNode,
    condition: ConditionNode,
    selection: SelectionNode,
    privacy: PrivacyNode,
    conjunction: ConjunctionNode
};

const initialNodes = [
    {
        id: uuidv4(),
        root: true,
        type: 'root',
        position: { x: 0, y: 0 },
        data: {
            general_properties: {
                label: 'Root',
                description: '',
            }
        },
    },
];

function ObjectDiagram() {

    // const profile = useSelector(getProfile);
    // const profile = JSON.parse(localStorage.getItem('iaam'));
    const dispatch = useDispatch();
    const openRightSide = useSelector(getOpenRightSide);
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [nodeSelected, setNodeSelected] = useState();
    const [openProperties, setOpenProperties] = useState(false);
    const objectFile = useSelector(getObjectFile);
    const [openSelectResource, setOpenSelectResource] = useState(false);
    const [openCreateCondition, setOpenCreateCondition] = useState(false);
    const [openAttributeSelection, setOpenAttributeSelection] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        zoomTo,
        getViewport,
        getNodes,
        getEdges,
        setViewport,
        fitView,
        screenToFlowPosition
    } = useReactFlow();

    // const [initialized, setInitialized] = useState(false);

    const drawerWidth = 300;

    const [profile, setProfile] = useState();

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
    }, []);

    const saveViewportState = useCallback(() => {
        const viewport = getViewport();
        const all_nodes = getNodes();
        const all_edges = getEdges();
        const viewport_flow = {
            nodes: all_nodes,
            edges: all_edges,
            viewport: viewport
        }
        const allFlows = JSON.parse(localStorage.getItem('object-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('object-file')) || {};
        allFlows[currentFile.object_diagram_id] = viewport_flow;
        localStorage.setItem('object-diagram', JSON.stringify(allFlows));
    }, [getViewport, getNodes, getEdges]);

    const restoreFlowState = (node_data, edge_data) => {
        const allFlows = JSON.parse(localStorage.getItem('object-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('object-file')) || {};
        const flowState = allFlows[currentFile.object_diagram_id];
        if (flowState) {
            setTimeout(() => {
                setNodes(node_data);
                setEdges(edge_data);
            }, 300);
            setTimeout(() => {
                setViewport(flowState.viewport || { x: 0, y: 0, zoom: 1 });
                setLoading(false);
            }, 500);
        } else {
            setTimeout(() => {
                setNodes(node_data);
                setEdges(edge_data);
            }, 300);
            setTimeout(() => {
                fitView();
                zoomTo(1, { duration: 0 });
                setLoading(false);
            }, 500);
        }
    };

    useEffect(() => {
        if (objectFile) {
            setNodeSelected(undefined);
            console.log('objectFile', objectFile);
            if (objectFile.data) {
                setLoading(true);
                restoreFlowState(objectFile.data.nodes, objectFile.data.edges);
            } else {
                setLoading(true);
                restoreFlowState(initialNodes, []);
            }
        }
    }, [objectFile])

    const onPaneClick = () => {
        // console.log('onPaneClick: ', nodes)
        setOpenProperties(false);
    };

    const onNodeClick = (event, node) => {
        // console.log("node", node)
        setNodeSelected(node);
        setOpenProperties(true);
    };

    const nodesWithUpdatedData = useMemo(() => {
        return nodes.map((node) => {
            if (node.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        isSelected: node.id === nodeSelected?.id,
                    },
                };
            }
            return node;
        });
    }, [nodes, nodeSelected]);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const type = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            let newNode = {};

            if (type === 'condition') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type} node`,
                            description: "",
                            conditions: []
                        }
                    },
                };
            } else if (type === 'selection') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type} node`,
                            description: ""
                        }
                    },
                };
            } else if (type === 'privacy') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type} node`,
                            description: ""
                        }
                    },
                };
            } else if (type === 'conjunction') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type} node`,
                            description: ""
                        }
                    },
                };
            }

            setNodes((nds) => nds.concat(newNode));
        },
        [screenToFlowPosition],
    );

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge({
            ...params,
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
            },
        }, eds)),
        [setEdges],
    );

    const handleClearDiagram = () => {
        setNodes([]);
        setEdges([]);
    };

    const handleSaveDiagram = async () => {
        const now = Date.now();
        let update = {
            object_diagram_id: objectFile.object_diagram_id,
            object_diagram_name: objectFile.object_diagram_name,
            creator_id: objectFile.creator_id,
            creator_name: objectFile.creator_name,
            last_update_id: profile.uprofile.inum,
            last_update_name: profile.uprofile.displayName,
            create_timestamp: objectFile.create_timestamp,
            update_timestamp: now,
            description: objectFile.description,
            complete: true,
        }
        if (nodes.length > 0 || edges.length > 0) {
            update = {
                ...update,
                data: {
                    nodes: nodes,
                    edges: edges
                }
            };
        }

        console.log("update", update)

        const requestOptions = {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(update)
        };

        const response = await fetch(process.env.REACT_APP_API_URL + `/object-diagram/update-object/${objectFile.object_diagram_id}`, requestOptions);
        if (response.status === 200) {
            const result = await response.json();
            alert('Object diagram updated successfully');
        } else {
            console.error(response.status);
            alert('Object diagram updated failed');
        }
    };

    useEffect(() => {
        if (nodes.length !== 0) {
            nodes.map((node) => {
                if (node.id === nodeSelected?.id) {
                    let update_current_node = {
                        ...node,
                        data: {
                            ...node.data,
                        },
                    };
                    setNodeSelected(update_current_node);
                }
            });
        }
    }, [nodes])

    const onNodesChange = useCallback(
        async (changes) => {
            setNodes((nodes) => {
                const oldNodes = nodes.map(
                    (node) => ({
                        ...node,
                        measured: {
                            width: node.measured?.width || 150,
                            height: node.measured?.height || 40,
                        }
                    })
                );
                return applyNodeChanges(changes, oldNodes);
            });
        },
        [setNodes],
    );

    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges],
    );

    const onConnectStart = () => {
        setNodes(nodes => nodes.map(node => ({ ...node, data: { ...node.data, showHandle: true } })));
    };

    const onConnectEnd = (event) => {
        setNodes(nodes => nodes.map(node => ({ ...node, data: { ...node.data, showHandle: false } })));
        if (!event.target.closest('.react-flow__handle')) {
            console.log('Connection was not made.');
        }
    };

    const onNodeDoubleClick = useCallback(() => {
        if (openRightSide) {
            dispatch(setOpenRightSide(openRightSide));
        } else {
            dispatch(setOpenRightSide(!openRightSide));
        }
    }, [nodes, edges]);

    const onReconnect = useCallback(
        (oldEdge, newConnection) =>
            setEdges((els) => reconnectEdge(oldEdge, newConnection, els)),
        [],
    );

    return (
        <div className='h-[calc(100vh-64px)] position-relative'>
            {objectFile &&
                <>
                    <ReactFlow
                        nodes={nodesWithUpdatedData}
                        edges={edges}
                        nodeTypes={nodeTypes}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onNodeClick={onNodeClick}
                        onPaneClick={onPaneClick}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onConnectStart={onConnectStart}
                        onConnectEnd={onConnectEnd}
                        onReconnect={onReconnect}
                        onMoveEnd={saveViewportState}
                        onNodeDoubleClick={onNodeDoubleClick}
                    // fitView
                    >
                        <MiniMap style={{ position: 'absolute', bottom: 0, left: 0, backgroundColor: 'none', width: 200 }} />
                        <Controls position="top-left">
                            <ControlButton onClick={() => handleSaveDiagram()}>
                                <SaveIcon />
                            </ControlButton>
                            <ControlButton onClick={() => handleClearDiagram()}>
                                <LayersClearIcon />
                            </ControlButton>
                        </Controls>
                        <Background />
                    </ReactFlow>
                    <Drawer
                        open={openRightSide}
                        anchor="right"
                        variant="persistent"
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginTop: "52px" },
                        }}
                    >
                        <ObjectRightSide
                            nodeSelected={nodeSelected}
                            nodes={nodes}
                            setNodes={setNodes}
                            open={openProperties}
                            setOpen={setOpenProperties}
                            setOpenSelectResource={setOpenSelectResource}
                            setOpenCreateCondition={setOpenCreateCondition}
                            setOpenAttributeSelection={setOpenAttributeSelection}
                        />
                    </Drawer>
                    <SelectResourceDialog
                        open={openSelectResource}
                        setOpen={setOpenSelectResource}
                        nodeSelected={nodeSelected}
                        setNodes={setNodes}
                    />
                    <CreateConditionDialog
                        open={openCreateCondition}
                        setOpen={setOpenCreateCondition}
                        nodeSelected={nodeSelected}
                        nodes={nodes}
                        setNodes={setNodes}
                    />
                    <AttributeSelection
                        open={openAttributeSelection}
                        setOpen={setOpenAttributeSelection}
                    />
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: 1,
                            backgroundColor: 'rgba(220, 220, 220, 1)',
                        }}
                        open={loading}
                        onClick={() => setLoading(false)}
                    >
                        <CircularProgress />
                    </Backdrop>
                </>
            }
        </div>
    )
}

export default ObjectDiagram