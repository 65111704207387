import { createSlice } from '@reduxjs/toolkit'

export const openRightSideSlice = createSlice({
    name: 'openRightSide',
    initialState: {
        open: false,
    },
    reducers: {
        setOpenRightSide: (state, action) => {
            state.open = action.payload;
        },
    },
})

export const { setOpenRightSide } = openRightSideSlice.actions;

export const getOpenRightSide = (state) => state.openRightSide.open;

export default openRightSideSlice.reducer