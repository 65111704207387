import { createSlice } from '@reduxjs/toolkit'

export const attributeSelectionSlice = createSlice({
    name: 'attributeSelection',
    initialState: {
        result: undefined,
    },
    reducers: {
        setAttributeSelection: (state, action) => {
            state.result = action.payload;
        },
    },
})

export const { setAttributeSelection } = attributeSelectionSlice.actions;

export const getAttributeSelection = (state) => state.attributeSelection.result;

export default attributeSelectionSlice.reducer