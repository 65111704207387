import { Typography } from '@mui/material'
import React from 'react'

function SimulatorPage() {
    return (
        <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 overflow-auto w-full">
            <div className='pt-10'>
                <Typography component='span' variant='h4' >IAAM Policy Simulator</Typography>
            </div>
        </section>
    )
}

export default SimulatorPage