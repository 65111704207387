import { createSlice } from '@reduxjs/toolkit'

export const drgMgtSlice = createSlice({
    name: 'drgMgt',
    initialState: {
        openDrawerLeft: true,
    },
    reducers: {
        setOpenDrawerLeft: (state, action) => {
            state.openDrawerLeft = action.payload;
        },
    },
})

export const { setOpenDrawerLeft } = drgMgtSlice.actions;

export const getOpenDrawerLeft = (state) => state.drgMgt.openDrawerLeft;

export default drgMgtSlice.reducer