import React, { useEffect, useState }  from 'react';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { CircularProgress, Stack } from '@mui/material';


export default function DPEInfoDialog(props) {
    const { open, setOpen, issuer } = props;
    
    const dpeInit = {url: "", conf: {}}
    const theme = useTheme();
    const [validDPE, setValidDPE] = useState(false);
    const [dpeDiscovery, setDPEDiscovery] = useState(dpeInit);
    const [error, setError] = useState(false);
    const [errMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    

    const handleClose = () => {
        setOpen(false);
        setDPEDiscovery(dpeInit)
        setValidDPE(false)
        setError(false)
    };


    const getDPEInfo = async () => {
        console.log(issuer)
        if(issuer){
            try{
                setIsLoading(true)
                const dpe_url = `${issuer}/.well-known/dpe-configuration`
                const res = await fetch(dpe_url)
                if(res.ok){
                    const dpe = await res.json()
                    setDPEDiscovery({url: dpe_url, conf: dpe})
                    setValidDPE(true)
                    setIsLoading(false)
                }
                else{
                    throw res
                }
            }
            catch (error) {
                setDPEDiscovery(dpeInit)
                setValidDPE(false)
                setErrorMsg(error.message)
                setError(true)
                setIsLoading(false)
            }
        }        
    }

    useEffect(()=>{
        if(open) getDPEInfo()
    },[open])

    return (
        <React.Fragment>
            <Dialog
                onClose={handleClose}
                aria-labelledby="dpe-info"
                open={open}
            >
                <DialogTitle className='text-white' sx={{ m: 0, py: '12px', px: '20px', backgroundColor: theme.palette.primary.main }} id="add-dpe-title">
                    DPE information
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{minHeight: "180px"}}>
                    {isLoading && 
                        <div className='flex my-4'>
                            <CircularProgress size={20}/>
                        </div>
                    }
                    {validDPE && 
                        <Stack spacing={2} sx={{mb: 2}}>
                            <div className="grid grid-cols-6 gap-4">
                                <Typography variant='inherit' className="col-start-1 col-span-2">Name:</Typography>
                                <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf?.resources_name}</Typography>
                            </div>
                            <div className="grid grid-cols-6 gap-4">
                                <Typography variant='inherit' className="col-start-1 col-span-2">Issuer:</Typography>
                                <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf?.issuer}</Typography>
                            </div>
                            <div className="grid grid-cols-6 gap-4">
                                <Typography variant='inherit' className="col-start-1 col-span-2">Resource Type:</Typography>
                                <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf?.resource_type}</Typography>
                            </div>
                            <div className="grid grid-cols-6 gap-4">
                                <Typography variant='inherit' className="col-start-1 col-span-2">Resource Status:</Typography>
                                <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf?.resource_connection?.status}</Typography>
                            </div>
                        </Stack>
                    }                    
                    
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}