import { IconButton, Typography, List, ListItem, ListItemText } from '@mui/material'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { clearSubjectFile, getSubjectFile, setSubjectFile } from '../../../../../redux/subjectFileSlice';
import { setOpenRightSide } from '../../../../../redux/openRightSideSlice';
import SearchFiles from '../../../../../components/SearchFiles';

function SubjectFiles(props) {
    const overflowingListFile = React.createRef();
    const [overflowActive, setOverflowActive] = useState(false);
    const { setOpen, subjectFiles, selectedItem, setSelectedItem } = props;
    const dispatch = useDispatch();
    const [filteredItems, setFilteredItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const selectFile = JSON.parse(localStorage.getItem('subject-file')) || {};
    const prevSelectIdFile = useRef();
    const [selectFile, setSelectFile] = useState();

    useEffect(() => {
        const selectFile = JSON.parse(localStorage.getItem('subject-file'));
        if (selectFile) {
            setSelectFile(selectFile);
        }
    }, []);

    const getSelectSubjectFile = async (subject_diagram_id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/subject-diagram/get-subject/${subject_diagram_id}`);
        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            console.error(response.status);
            return;
        }
    };

    const onClickSubjectFile = async (item) => {
        setSelectedItem(item.subject_diagram_id);
        const subject_file = await getSelectSubjectFile(item.subject_diagram_id);
        dispatch(setSubjectFile(subject_file));
        localStorage.setItem('subject-file', JSON.stringify(subject_file));
        // dispatch(setOpenRightSide(true));
    };

    const handleSearch = (term) => {
        const filtered = subjectFiles.filter(item =>
            item.subject_diagram_name.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredItems(filtered);
    };

    useEffect(() => {
        if (subjectFiles) {
            setFilteredItems(subjectFiles);
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [subjectFiles])

    useLayoutEffect(() => {
        const { current } = overflowingListFile;
        const trigger = () => {
            const hasOverflow = current.scrollHeight > current.clientHeight;
            setOverflowActive(hasOverflow);
        };

        if (current) {
            if ('ResizeObserver' in window) {
                new ResizeObserver(trigger).observe(current);
            }
            trigger();
        }

    }, [overflowingListFile]);

    useEffect(() => {
        if (selectFile) {
            if (prevSelectIdFile.current === undefined && selectFile.subject_diagram_id) {
                const getLocalStorageFile = async (subject_diagram_id) => {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/subject-diagram/get-subject/${subject_diagram_id}`);
                    if (response.ok) {
                        const result = await response.json();
                        setSelectedItem(result.subject_diagram_id);
                        dispatch(setSubjectFile(result));
                        localStorage.setItem('subject-file', JSON.stringify(result));
                    } else {
                        console.error(response.status);
                    }
                };
                getLocalStorageFile(selectFile.subject_diagram_id);
                prevSelectIdFile.current = selectFile.subject_diagram_id;
            }
        }
    }, [selectFile])

    return (
        <div className='h-full'>
            <Typography variant='body2' className='px-3 py-3 text-gray-500'>Subject Files</Typography>
            <SearchFiles onSearch={handleSearch} onAddClick={setOpen} isOverflow={overflowActive} isLoading={isLoading} />
            <List ref={overflowingListFile} className='h-[calc(100%-150px)]' sx={{ padding: 0, overflowY: "auto" }} >
                {filteredItems.map((item) => {
                    return (
                        <ListItem
                            key={item.subject_diagram_id}
                            disablePadding
                            className='pl-1 py-2 border-b hover:bg-slate-50 cursor-pointer'
                            sx={selectedItem === item.subject_diagram_id ?
                                {
                                    backgroundColor: 'rgba(25,118,210,0.1)'
                                } : {}
                            }
                            secondaryAction={
                                <IconButton edge="end" aria-label="more" sx={{ width: '24px', height: '24px', right: 3 }}>
                                    <MoreVertIcon fontSize="small" className='text-gray-400' />
                                </IconButton>
                            }
                            onClick={() => onClickSubjectFile(item)}
                        >
                            <ListItemText
                                className='text-gray-500'
                                primary={item.subject_diagram_name}
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    paddingLeft: '12px',
                                    color: selectedItem === item.subject_diagram_id && '#1976d2',
                                    fontWeight: selectedItem === item.subject_diagram_id && 'bold'
                                }}
                            />

                        </ListItem>
                    )
                })}
            </List>

        </div>
    )
}

export default SubjectFiles