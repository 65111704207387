import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, TextField, useMediaQuery } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { getProfile } from '../../../../../redux/iaamSlice';
import { getProject } from '../../../../../redux/projectSlice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function CreateObjectDialog(props) {

    const theme = useTheme();
    const { open, setOpen, setStatusUpdate } = props;
    // const profile = useSelector(getProfile);
    // const profile = JSON.parse(localStorage.getItem('iaam'));
    // const project = useSelector(getProject);
    // const project = JSON.parse(localStorage.getItem('project'));
    const [description, setDescription] = React.useState("");
    const [disabled, setDisabled] = React.useState(true);

    const [objectDiagramName, setObjectDiagramName] = React.useState("");
    const [objectDiagramNameError, setObjectDiagramNameError] = React.useState(false);
    const [objectDiagramNameTextError, setObjectDiagramNameTextError] = React.useState("");

    const [profile, setProfile] = React.useState();
    const [project, setProject] = React.useState();

    React.useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
    }, []);

    React.useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const handleClose = () => {
        setObjectDiagramName("")
        setObjectDiagramNameError(false)
        setObjectDiagramNameTextError("")
        setDescription("")
        setDisabled(true)
        setOpen(false);
    };

    const handleValidation = async () => {

        if (objectDiagramName !== "") {
            const duplicate = await handleDuplicate();
            if (!duplicate) {
                setObjectDiagramNameError(false)
                setObjectDiagramNameTextError("")
                const trim_object_name = objectDiagramName.trim();
                const id = uuidv4();
                const create_ts = Date.now();

                const requestOptions = {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        object_diagram_id: id,
                        object_diagram_name: trim_object_name,
                        creator_id: profile.uprofile.inum,
                        creator_name: profile.uprofile.displayName,
                        last_update_id: profile.uprofile.inum,
                        last_update_name: profile.uprofile.displayName,
                        create_timestamp: create_ts,
                        update_timestamp: create_ts,
                        description: description,
                        complete: true,
                    })
                };

                const response = await fetch(process.env.REACT_APP_API_URL + '/object-diagram/add-object/' + project.project.project_id, requestOptions);
                if (response.status === 200) {
                    const result = await response.json();
                    handleClose();
                    setStatusUpdate(true);
                } else {
                    console.error(response.status);
                }
            }
        } else {
            setObjectDiagramNameError(true)
            setObjectDiagramNameTextError("Incomplete information")
            setDisabled(true)
        }

    };

    const handleDuplicate = async () => {
        if (!objectDiagramName) {
            setObjectDiagramNameError(true)
            setObjectDiagramNameTextError("Incomplete information")
            setDisabled(true)
            return true;
        } else {
            const trim_object_name = objectDiagramName.trim();
            const requestOptions = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    project_id: project.project.project_id,
                    object_diagram_name: trim_object_name
                })
            };
            const response = await fetch(process.env.REACT_APP_API_URL + '/object-diagram/check-duplicate-object', requestOptions);
            if (response.status === 200) {
                const result = await response.json();
                setDisabled(result.isDuplicate)
                if (result.isDuplicate) {
                    setObjectDiagramNameError(true)
                    setObjectDiagramNameTextError("Duplicate File name")
                    return true;
                } else {
                    setObjectDiagramNameError(false)
                    setObjectDiagramNameTextError("")
                    return false;
                }
            } else {
                console.error(response.status);
                setObjectDiagramNameError(true)
                setObjectDiagramNameTextError("System failure")
                setDisabled(true)
                return true;
            }
        }
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth
                maxWidth="xs"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 2, borderTopRightRadius: 2 }} id="customized-dialog-title">
                    <div>
                        <Typography variant='h6' color={theme.palette.white.main}>Create Object</Typography>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: theme.palette.white.main
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box sx={{ paddingX: 0, paddingY: 0 }}>
                        <TextField
                            required
                            id="object-name"
                            label="Object Name"
                            fullWidth
                            margin="normal"
                            value={objectDiagramName}
                            onChange={(e) => setObjectDiagramName(e.target.value)}
                            onBlur={handleDuplicate}
                            error={objectDiagramNameError}
                            helperText={objectDiagramNameTextError}
                        />
                        <TextField
                            id="description"
                            label="Description"
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ m: 0, paddingX: 1, paddingY: 1 }}>
                        <Button variant="contained" disabled={disabled} onClick={() => handleValidation()}>Create</Button>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default CreateObjectDialog