import React, { useEffect } from 'react'
import DRGPageToolBar from '../../../components/DRGPageToolBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PolylineIcon from '@mui/icons-material/Polyline';
import GroupIcon from '@mui/icons-material/Group';
import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenDrawerLeft, setOpenDrawerLeft } from '../../../redux/drgMgtSlice';
import DRGOutlet from './DRGOutlet';
import SubjectPage from './Subject/SubjectPage';
import ObjectPage from './Object/ObjectPage';
import RegulatoryPage from './Regulatory/RegulatoryPage';

function DRGLayout() {

    const location = useLocation();
    const currentTab = '/' + location.pathname.split('/')[2];

    const openDrawerLeft = useSelector(getOpenDrawerLeft);
    const dispatch = useDispatch();
    const [tabSelected, setTabSelected] = React.useState("");

    const habdleTabClick = (event) => {
        if (location.pathname.split('/')[2] === tabSelected) {
            dispatch(setOpenDrawerLeft(!openDrawerLeft));
        }

    }

    useEffect(() => {
        if (location.pathname) {
            setTabSelected(location.pathname.split('/')[2])
            dispatch(setOpenDrawerLeft(true));
        }
    }, [location.pathname])

    return (
        <Box sx={{ overflow: 'hidden', bgColor: '#f3f3f3' }}>
            <DRGPageToolBar />
            <div className='flex mt-[52px] h-[calc(100vh-52px)]'>
                <Tabs
                    orientation="vertical"
                    value={currentTab}
                    aria-label="drg tabs "
                    sx={{ borderRight: 1, borderColor: 'divider', bgcolor: 'background.paper', minWidth: 70, flex: 1, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                >
                    <Tab
                        label="Subject"
                        value="/subject"
                        name="subject"
                        component={Link}
                        to="/drg/subject"
                        icon={<GroupIcon sx={{ width: 20, height: 20 }} />}
                        sx={{
                            boxShadow: (currentTab === "/subject" && 3),
                            textTransform: 'none',
                            fontSize: 11,
                            padding: 1.5,
                            minWidth: 50
                        }}
                        onClick={habdleTabClick}
                    />
                    <Tab
                        label="Object"
                        value="/object"
                        name="object"
                        component={Link}
                        to="/drg/object"
                        icon={<ShapeLineIcon sx={{ width: 20, height: 20 }} />}
                        sx={{
                            boxShadow: (currentTab === "/object" && 3),
                            textTransform: 'none',
                            fontSize: 11,
                            padding: 1.5,
                            minWidth: 50
                        }}
                        onClick={habdleTabClick}
                    />
                    <Tab
                        label="Regulatory"
                        value="/regulatory"
                        name="regulatory"
                        component={Link}
                        to="/drg/regulatory"
                        icon={<PolylineIcon sx={{ width: 20, height: 20 }} />}
                        sx={{
                            boxShadow: (currentTab === "/regulatory" && 3),
                            textTransform: 'none',
                            fontSize: 11,
                            padding: 1.5,
                            minWidth: 50
                        }}
                        onClick={habdleTabClick}
                    />
                </Tabs>
                <Routes>
                    <Route path="drg/*" element={<DRGOutlet />} />
                    <Route path="subject/*" element={<SubjectPage />} />
                    <Route path="object/*" element={<ObjectPage />} />
                    <Route path="regulatory/*" element={<RegulatoryPage />} />
                    <Route path="/" element={<Navigate to="/drg/subject" />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        </Box>
    )
}

export default DRGLayout