import { createSlice } from '@reduxjs/toolkit'

export const objectFileSlice = createSlice({
    name: 'objectFile',
    initialState: {
        result: undefined,
    },
    reducers: {
        setObjectFile: (state, action) => {
            state.result = action.payload;
        },
        clearObjectFile: (state) => {
            state.result = undefined;
        }
    },
})

export const { setObjectFile, clearObjectFile } = objectFileSlice.actions;

export const getObjectFile = (state) => state.objectFile.result;

export default objectFileSlice.reducer