import { createSlice } from '@reduxjs/toolkit'

export const dpeConfigSlice = createSlice({
    name: 'dpeConfig',
    initialState: {
        config: undefined,
    },
    reducers: {
        setDpeConfig: (state, action) => {
            state.config = action.payload;
        },
    },
})

export const { setDpeConfig } = dpeConfigSlice.actions;

export const getDpeConfig = (state) => state.dpeConfig.config;

export default dpeConfigSlice.reducer