import React, { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import SubjectPage from './Subject/SubjectPage';
import ObjectPage from './Object/ObjectPage';
import RegulatoryPage from './Regulatory/RegulatoryPage';

function DRGOutlet() {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/drg') {
            navigate('subject');
        }
    }, [location])

    return (
        <>
            <Routes>
                <Route path="subject" element={<SubjectPage />} />
                <Route path="object" element={<ObjectPage />} />
                <Route path="regulatory" element={<RegulatoryPage />} />
                <Route path="/" element={<Navigate to="/drg/subject" />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </>
    )
}

export default DRGOutlet