import React from 'react'
import { Handle, Position } from '@xyflow/react';
import { Stack, Typography, useTheme } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

const PrivacyNode = ({ data }) => {

    const theme = useTheme();

    return (
        <div style={{
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '120px',
            backgroundColor: '#fff',
            border: data.isSelected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[500]}`,
            borderRadius: '5px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }}
        >
            <Stack
                direction='row'
            >
                <div
                    style={{
                        width: '40px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: 'auto',
                        backgroundColor: '#1976d2',
                        padding: '5px 10px'
                    }}
                >
                    <LockIcon
                        color="white"
                        sx={{
                            width: 20,
                            height: 20,
                        }}
                    />
                </div>
                <div
                    style={{
                        flex: 1,
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'inline-block',
                        padding: '10px',
                    }}
                >
                    <Typography
                        variant='subtitle2'
                        fontSize={{
                            lg: 12,
                            md: 10,
                            sm: 10,
                            xs: 10
                        }}
                        sx={{
                            cursor: 'pointer',
                            textAlign: 'center',
                        }}
                    >
                        {data.general_properties.label}
                    </Typography>
                </div>
            </Stack>
            <Handle
                id="top-target"
                type="target"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: data.isSelected ? 'white' : 'transparent',
                    border: data.isSelected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="top-source"
                type="source"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: data.isSelected ? 'white' : 'transparent',
                    border: data.isSelected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="bottom-target"
                type="target"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: data.isSelected ? 'white' : 'transparent',
                    border: data.isSelected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="bottom-source"
                type="source"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: data.isSelected ? 'white' : 'transparent',
                    border: data.isSelected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="left-target"
                type="target"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: data.isSelected ? 'white' : 'transparent',
                    border: data.isSelected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="left-source"
                type="source"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: data.isSelected ? 'white' : 'transparent',
                    border: data.isSelected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="right-target"
                type="target"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: data.isSelected ? 'white' : 'transparent',
                    border: data.isSelected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="right-source"
                type="source"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: data.isSelected ? 'white' : 'transparent',
                    border: data.isSelected ? '1px solid #1976d2' : 'none',
                }}
            />
        </div>
    );
};

export default PrivacyNode;