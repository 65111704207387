import React from 'react'
import Typography from '@mui/material/Typography';
import { CardActionArea, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';

const CardContainer = styled(CardActionArea)({
    width: 280,
    minHeight: 50,
    padding: 0,
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: 'rgba(112, 112, 112, 0.5)',
    borderStyle: 'dashed',
    borderRadius: 5,
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
});

function CardAdd(props) {

    const { setOpen } = props;

    const handleClickOpen = () => {
        // setOpen(true);
    };

    return (
        <CardContainer onClick={handleClickOpen}>
            <CardContent sx={{ padding: 0 }}>
                <Typography sx={{ fontSize: 24 }} color="text.secondary" >
                    +
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: 'regular' }} color="text.secondary" gutterBottom>
                    New Policy
                </Typography>
            </CardContent>
        </CardContainer>
    )
}

export default CardAdd