import React, { useState } from 'react'
import { Handle } from '@xyflow/react';
import { IconButton, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const CustomHandle = ({ type, position, data, theme }) => {
    return (
        <Handle
            type={type}
            position={position}
            style={{
                width: 'auto',
                height: 'auto',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                paddingTop: '8px'
            }}
        >
            <IconButton onClick={data.onAddNode} sx={{
                backgroundColor: '#fff',
                border: `1px solid ${theme.palette.primary.main}`,
                width: data.isSelected ? 6 : 0,
                height: data.isSelected ? 6 : 0,
                padding: data.isSelected ? '6px' : '0px',
            }}>
                <AddIcon color="primary" sx={{ width: 12, height: 12 }} />
            </IconButton>
        </Handle>
    );
};

const SubjectNode = ({ data }) => {

    const theme = useTheme();

    return (
        <div style={{
            padding: '0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '80px',
        }}
        >
            <div style={{ marginBottom: '0px' }}>
                <svg version="1.1" viewBox="0 0 2048 2048" width="40" height="40" fill={data.isSelected ? '#1976d2' : theme.palette.grey["700"]} xmlns="http://www.w3.org/2000/svg">
                    <path transform="translate(897,1199)" d="m0 0h251l35 1 42 3 32 4 35 6 28 6 32 8 38 12 28 10 32 13 28 13 32 16 24 14 17 10 30 20 18 13 16 12 14 11 13 11 11 9 16 15 8 7 35 35 7 8 11 12 9 11 11 13 13 17 10 13 14 20 12 18 13 21 10 17 13 24 9 17 13 28 13 31 11 30 11 34 10 37 8 36 7 43 4 34 2 23 1 19v43l-4 14-6 11-8 10-13 9-11 5-6 2h-1706l-15-7-10-7-9-9-8-13-4-13-1-10 1-45 4-46 5-36 6-34 9-38 9-32 12-37 17-43 13-29 15-30 13-23 8-14 15-24 13-19 12-17 16-21 11-14 9-10 8-10 3-4h2l2-4 9-9 7-8 6-7h2l2-4h2l2-4h2v-2l8-7 14-14 8-7 10-9 11-9 17-14 13-10 19-14 16-11 17-11 21-13 21-12 23-12 15-8 29-13 27-11 28-10 31-10 38-10 32-7 36-6 44-5 31-2z" />
                    <path transform="translate(976)" d="m0 0h103v1l50 8 31 7 28 8 31 11 28 12 29 14 23 13 19 12 19 13 19 14 13 11 11 9 16 15 5 5 7 6 7 8 10 10 9 11 9 10 13 17 14 19 11 17 9 15 12 21 14 28 14 34 12 35 9 34 7 36 5 41 1 16v58l-2 26-4 30-4 23-8 34-11 35-9 24-9 21-15 31-13 23-12 19-13 19-12 16-13 16-9 11-6 7h-2l-2 4-30 30h-2v2l-11 9-11 10-16 12-19 14-17 11-20 12-18 10-19 10-32 14-28 10-25 8-28 7-31 6-29 4-21 2-17 1h-46l-30-2-36-5-31-6-34-9-33-11-27-11-30-14-24-13-18-11-24-16-16-12-14-11-13-11-12-11-8-7-20-20v-2h-2l-7-8-9-10-9-11-8-10-13-18-12-17-12-20-12-21-14-28-12-28-13-37-9-33-7-34-5-36-2-23-1-21v-26l2-35 3-27 7-39 9-36 12-36 11-28 13-28 10-19 16-27 8-12 12-17 12-16 13-16 9-10 7-8 5-6h2l2-4 12-12h2v-2l8-7 13-12 11-9 16-13 17-12 15-10 19-12 23-13 24-12 28-12 27-10 33-10 35-8 36-6z" />
                </svg>
            </div>
            <div style={{
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
            }}>
                <Typography
                    variant='subtitle2'
                    fontSize={{
                        lg: 12,
                        md: 10,
                        sm: 10,
                        xs: 10
                    }}
                    sx={{
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginBottom: '0px',
                    }}
                >
                    {data.general_properties.label}
                </Typography>
            </div>
            {data.general_properties.subject_constraint.length > 0 &&
                <div>
                    <Typography
                        variant='subtitle2'
                        fontSize={{
                            lg: 12,
                            md: 10,
                            sm: 10,
                            xs: 10
                        }}
                        sx={{
                            textAlign: 'center',
                            margin: '4px 0px 4px 0px',
                            // marginBottom: '8px',
                            padding: '2px 10px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            backgroundColor: 'rgba(0, 0, 0,0.1)'
                        }}
                    >
                        {data.general_properties.subject_constraint[0]}
                    </Typography>
                </div>
            }
            {data.isSelected ?
                <CustomHandle
                    type="source"
                    position="bottom"
                    data={data}
                    style={{
                        background: 'transparent',
                        width: 10,
                        height: 10,
                    }}
                    theme={theme}
                    isConnectable={false}
                /> :
                <Handle
                    type="source"
                    position="bottom"
                    style={{
                        background: 'transparent',
                        border: 'none',
                    }}
                    isConnectable={false}
                />
            }
            <Handle
                type="target"
                position="top"
                style={{ background: 'transparent', width: 10, height: 10, border: 'none' }}
                isConnectable={false}
            />
        </div>
    );
};

export default SubjectNode;