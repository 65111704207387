import { createSlice } from '@reduxjs/toolkit'

export const projectSlice = createSlice({
    name: 'project',
    initialState: {
        result: undefined,
    },
    reducers: {
        setProject: (state, action) => {
            state.result = action.payload;
        },
    },
})

export const { setProject } = projectSlice.actions;

export const getProject = (state) => state.project.result;

export default projectSlice.reducer