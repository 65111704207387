import { createSlice } from '@reduxjs/toolkit'

export const subjectFileSlice = createSlice({
    name: 'subjectFile',
    initialState: {
        result: undefined,
    },
    reducers: {
        setSubjectFile: (state, action) => {
            state.result = action.payload;
        },
        clearSubjectFile: (state) => {
            state.result = undefined;
        }
    },
})

export const { setSubjectFile, clearSubjectFile } = subjectFileSlice.actions;

export const getSubjectFile = (state) => state.subjectFile.result;

export default subjectFileSlice.reducer