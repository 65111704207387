import { Avatar, Badge, CircularProgress, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Paper, Stack, styled, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useEffect, useLayoutEffect, useState } from "react";
import { getProject } from "../redux/projectSlice";
import { useSelector } from "react-redux";
import AddDPEDialog from "./AddDPEDialog";
import ConfirmDoalog from "./ConfirmDoalog";
import DPEInfoDialog from "./DPEInfoDialog";

export default function DPEManagement() {

    const overflowingListFile = React.createRef();
    const [overflowActive, setOverflowActive] = useState(false);
    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [DPEList, setDPEList] = useState([]);
    const [isDPEUpdate, setIsDPEUpdate] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [DPESeleted, setDPESeleted] = useState({ id: undefined, name: undefined });
    // const project = useSelector(getProject);
    // const project = JSON.parse(localStorage.getItem('project'));
    const [project, setProject] = useState();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const onAddClick = () => {
        setOpen(true)
    }

    const StyledBadge = styled(Badge)(({ theme, color }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: color ? color : "#44b700",
            color: color ? color : "#44b700",
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    const getListDPE = async () => {
        setIsLoading(true)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dpe/get-dpes/${project.project.project_id}`);
        if (response.ok) {
            let DPEs = []
            const dicovery = await response.json();
            for (const dLink of dicovery) {
                const dpe_resp = await fetch(dLink.dpe_discovery_url);
                if (dpe_resp.ok) {
                    const dpe = await dpe_resp.json();
                    DPEs.push({ key: dLink.dpe_id, ...dpe })
                }
                else {
                    DPEs.push({
                        key: dLink.dpe_id,
                        resources_name: dLink.dpe_name,
                        resource_type: "DB_Null",
                        resource_connection: {
                            status: "Error",
                            message: "connection refused!"
                        }
                    })
                }
            }

            setDPEList(DPEs);
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (project) {
            getListDPE();
        }
    }, [project])

    useEffect(() => {
        if (isDPEUpdate) {
            getListDPE();
            setIsDPEUpdate(false)
        }
    }, [isDPEUpdate])

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleActionClick = (e) => {
        setDPESeleted({ id: e.currentTarget.id, name: e.currentTarget.name, issuer: e.currentTarget.value })
        setAnchorEl(e.currentTarget);
    }

    const handleOpenDeleteDPEConfirm = (e) => {
        setOpenDeleteConfirm(true)
        setAnchorEl(null)
    }

    const handleDeleteDPE = async () => {
        try {
            let options = {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    "dpe_ids": [DPESeleted.id]
                })
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/dpe/delete-dpes`, options);
            if (response.ok) {
                setIsDPEUpdate(true)
            }
            else throw response
        }
        catch (error) {

        }
    }

    const handleDPEInfo = async () => {
        setOpenInfo(true)
        setAnchorEl(null)
    }

    useLayoutEffect(() => {
        const { current } = overflowingListFile;
        const trigger = () => {
            const hasOverflow = current.scrollHeight > current.clientHeight;
            setOverflowActive(hasOverflow);
        };

        if (current) {
            if ('ResizeObserver' in window) {
                new ResizeObserver(trigger).observe(current);
            }

            trigger();
        }

    }, [overflowingListFile]);

    return (
        <div className="h-full">
            <Paper
                component="form"
                square
                variant={overflowActive ? "elevation" : "outlined"}
                className='p-2 flex items-center h-[50px] mb-[2px] border-t'
                sx={{ borderLeft: 0, borderRight: 0 }}
            >
                <Typography variant='subtitle2' className='w-[240px]'>Data Proxy Engine</Typography>
                <IconButton
                    className='flex-col-reverse'
                    style={{
                        height: '24px',
                        width: '24px'
                    }}
                    onClick={() => onAddClick(true)}
                >

                    {isLoading &&
                        <CircularProgress size={20} />
                    }
                    {!isLoading &&
                        <AddCircleIcon className='text-gray-400 hover:text-blue-600' />
                    }
                </IconButton>
            </Paper>

            <div className="overflow-auto h-[calc(100%-106px)] min-h-[70px]" >
                <List ref={overflowingListFile} sx={{ paddingY: 0 }}>
                    {DPEList.map((item) => {
                        return (
                            <ListItem
                                key={item.key}
                                className="border-b"
                                secondaryAction={
                                    <IconButton edge="end" aria-label="more" sx={{ width: '24px', height: '24px', right: 3 }} id={item.key} name={item.resources_name} value={item.issuer} onClick={handleActionClick}>
                                        <MoreVertIcon fontSize="small" className='text-gray-400' />
                                    </IconButton>
                                }
                            >
                                <StyledBadge
                                    className="mr-4"
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                    color={item.resource_connection.status === "OK" ? "#44b700" : "#d20707"}
                                >
                                    <Avatar className="p-1" sx={{ width: 32, height: 32 }} alt="resource type" src={`/image/${item.resource_type}.png`} />
                                </StyledBadge>
                                <ListItemText
                                    className='text-gray-500'
                                    primary={item.resources_name}
                                    secondary={item.resource_connection.message}
                                    sx={{ borderRadius: "16px" }}
                                    primaryTypographyProps={{
                                        fontSize: 14
                                    }}
                                    secondaryTypographyProps={{
                                        fontSize: 12
                                    }}
                                />

                            </ListItem>
                        )
                    })}
                </List>
                <Menu
                    id="dpe-delete-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={handleOpenDeleteDPEConfirm}>Delete</MenuItem>
                    <MenuItem onClick={handleDPEInfo}>information</MenuItem>
                </Menu>

            </div>
            <AddDPEDialog open={open} setOpen={setOpen} setIsDPEUpdate={setIsDPEUpdate}></AddDPEDialog>
            <ConfirmDoalog
                open={openDeleteConfirm}
                setOpen={setOpenDeleteConfirm}
                onConfirm={handleDeleteDPE}
                title="Delete Project"
                description={`Are you sure you want delete DPE: "${DPESeleted.name}"`}
                confirmLabel="Delete"
                confirmColor="error"
            />
            <DPEInfoDialog open={openInfo} setOpen={setOpenInfo} issuer={DPESeleted.issuer} />
        </div>
    )
}