import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { IconButton, List, ListItem, ListItemText } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import SearchFiles from '../../../../../components/SearchFiles';
import { getProject } from '../../../../../redux/projectSlice';
import { setOpenRightSide } from '../../../../../redux/openRightSideSlice';
import { getRegulatoryFile, setRegulatoryFile } from '../../../../../redux/regulatoryFileSlice';

function RegulatoryFiles(props) {

    const { statusUpdate, setStatusUpdate, setOpenCreate } = props;

    const overflowingListFile = React.createRef();
    const [overflowActive, setOverflowActive] = useState(false);
    const dispatch = useDispatch();
    // const project = useSelector(getProject);
    // const project = JSON.parse(localStorage.getItem('project'));
    const [regulatoryFiles, setRegulatoryFiles] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const regulatoryFile = useSelector(getRegulatoryFile);
    const [isLoading, setIsLoading] = useState(false);
    const [project, setProject] = useState();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const handleSearch = (term) => {
        const filtered = regulatoryFiles.filter(item =>
            item.regulatory_diagram_name.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredItems(filtered);
    };

    const getSelectRegulatoryFile = async (regulatory_diagram_id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/regulatory-diagram/get-regulatory/${regulatory_diagram_id}`);
        if (response.ok) {
            const result = await response.json();
            dispatch(setRegulatoryFile(result));
            localStorage.setItem('regulatory-file', JSON.stringify(result));
        } else {
            console.error(response.status);
        }
    };

    const onClickObjectFile = (item) => {
        getSelectRegulatoryFile(item.regulatory_diagram_id);
        // dispatch(setOpenRightSide(true));
        setSelectedItem(item.regulatory_diagram_id);
    };

    const getListRegulatorys = async () => {
        setIsLoading(true)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/regulatory-diagram/get-regulatories/${project.project.project_id}`);
        if (response.ok) {
            const result = await response.json();
            setRegulatoryFiles(result);
            setIsLoading(false)
        } else {
            console.error(response.status);
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (project) {
            getListRegulatorys();
        }
    }, [project])

    useEffect(() => {
        if (statusUpdate) {
            getListRegulatorys();
            setStatusUpdate(false);
        }
    }, [statusUpdate])

    useEffect(() => {
        if (regulatoryFiles) {
            setFilteredItems(regulatoryFiles);
        }
    }, [regulatoryFiles])

    useEffect(() => {
        if (regulatoryFile) {
            setSelectedItem(regulatoryFile.regulatory_diagram_id);
        }
    }, [regulatoryFile])

    useLayoutEffect(() => {
        const { current } = overflowingListFile;
        const trigger = () => {
            const hasOverflow = current.scrollHeight > current.clientHeight;
            setOverflowActive(hasOverflow);
        };

        if (current) {
            if ('ResizeObserver' in window) {
                new ResizeObserver(trigger).observe(current);
            }

            trigger();
        }

    }, [overflowingListFile]);

    return (
        <div className='h-full'>
            <SearchFiles onSearch={handleSearch} onAddClick={setOpenCreate} isOverflow={overflowActive} isLoading={isLoading} />
            <List ref={overflowingListFile} className='h-[calc(100%-106px)]' sx={{ padding: 0, overflowY: "auto" }} >
                {filteredItems.map((item) => {
                    return (
                        <ListItem
                            key={item.regulatory_diagram_id}
                            disablePadding
                            className='pl-1 py-2 border-b hover:bg-slate-50 cursor-pointer'
                            sx={selectedItem === item.regulatory_diagram_id ?
                            {
                                backgroundColor: 'rgba(25,118,210,0.1)'
                            } : {
                                backgroundColor: 'rgba(255,255,255,0.1)'
                            }
                            }
                            secondaryAction={
                                <IconButton edge="end" aria-label="more" sx={{ width: '24px', height: '24px', right: 3 }}>
                                    <MoreVertIcon fontSize="small" className='text-gray-400' />
                                </IconButton>
                            }
                            onClick={() => onClickObjectFile(item)}
                        >
                            <ListItemText
                                className='text-gray-500'
                                primary={item.regulatory_diagram_name}
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    paddingLeft: '12px',
                                    color: selectedItem === item.regulatory_diagram_id && '#1976d2',
                                    fontWeight: selectedItem === item.regulatory_diagram_id && 'bold'
                                }}
                            />
                        </ListItem>
                    )
                })}
            </List>
        </div>
    )
}

export default RegulatoryFiles