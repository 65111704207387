import { useSelector } from "react-redux";
import { getIAAM, getProfile } from "../redux/iaamSlice";
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react'

export default function ProfileCard(props) {
    // const profile = useSelector(getProfile);
    // const profile = JSON.parse(localStorage.getItem('iaam'));
    const iaam = useSelector(getIAAM);
    const [profile, setProfile] = useState();

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
    }, []);

    const { setAnchorEl } = props

    const handleSignoutClick = () => {
        setAnchorEl(null);
        iaam.end_session()
    }

    return (
        <Card className='w-[320px]'>
            <CardMedia
                sx={{ height: 220 }}
                image="/image/user.jpg"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {profile?.uprofile?.displayName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {profile?.uprofile?.org_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {profile?.uprofile?.mail}
                </Typography>
            </CardContent>
            <CardActions className='flex justify-between'>
                <a href={process.env.REACT_APP_IAAMREG_URL}>
                    <Button size="medium">Profile</Button>
                </a>
                <Button size="medium" color='error' onClick={handleSignoutClick}>Sign Out</Button>
            </CardActions>
        </Card>
    )
}