import React, { Fragment, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Button, Stack, Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAttributeSelection, setAttributeSelection } from '../../../../../redux/attributeSelectionSlice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function AttributeSelection(props) {

    const theme = useTheme();
    const { open, setOpen } = props;
    const attributeSelection = useSelector(getAttributeSelection);
    const dispatch = useDispatch();
    const [attributeSelected, setAttributeSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(true);

    const handleCheckboxChange = (event, id) => {
        const update = attributeSelected.map((attr) => {
            if (attr.id === id) {
                return {
                    ...attr,
                    checked: event.target.checked
                }
            }
            return attr;
        })
        setAttributeSelected(update)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        // const attribute_selection_selected = attributeSelected.filter((attr) => {
        //     return attr.checked === true;
        // });
        const new_result = {
            ...attributeSelection,
            attribute_selections: attributeSelected
        };
        dispatch(setAttributeSelection(new_result));
        handleClose();
    };

    useEffect(() => {
        if (attributeSelection?.attribute_selections !== undefined) {
            setAttributeSelected(attributeSelection.attribute_selections);
        }
    }, [attributeSelection])

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth
                maxWidth="xs"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 2, borderTopRightRadius: 2 }} id="customized-dialog-title">
                    <div style={{ width: '100%' }}>
                        <Typography variant='h6' color={theme.palette.white.main}>Attribute Selection</Typography>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: theme.palette.white.main
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div>
                        <Stack
                            sx={{
                                width: '100%',
                                border: '1px solid #ccc',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                                alignItems: 'center',
                                textAlign: 'center',
                                padding: '10px'
                            }}
                            direction='row'
                        >
                            <Box display="flex">
                                <Typography
                                    variant="caption"
                                    display="block"
                                    color="primary"
                                    noWrap
                                    sx={{
                                        marginRight: '5px',
                                    }}
                                >
                                    Resource:
                                </Typography>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    color="black"
                                    noWrap
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        // maxWidth: '40%'
                                        maxWidth: '200px'
                                    }}
                                >
                                    {attributeSelection?.resource}
                                </Typography>
                            </Box>
                        </Stack>
                        <div
                            style={{
                                border: '1px solid #ccc',
                                borderBottomLeftRadius: '5px',
                                borderBottomRightRadius: '5px',
                                maxHeight: 'calc(100vh - 390px)',
                                overflow: 'auto'
                            }}
                        >
                            <List sx={{ padding: '0px' }}>
                                <ListItem
                                    sx={{
                                        padding: '5px 0px 5px 10px',
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectAll}
                                            onChange={(event) => setSelectAll(event.target.checked)}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Select All" />
                                </ListItem>
                                {attributeSelected.map((item, index) => {
                                    return (
                                        <ListItem
                                            sx={{
                                                padding: '5px 0px 5px 10px',
                                            }}
                                            key={index}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={item.checked}
                                                    onChange={(event) => handleCheckboxChange(event, item.id)}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ m: 0, paddingX: 1, paddingY: 1 }}>
                        <Button variant="contained" onClick={() => handleSave()}>Save</Button>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </Fragment>
    )
}

export default AttributeSelection