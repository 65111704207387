import { useEffect, useState } from 'react';
import './App.css';
import { useDispatch } from 'react-redux';
import { setIAAM, setProfile } from './redux/iaamSlice';
import UserPermission from './pages/UserPermission';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import Main from './pages/Main';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    white: {
      main: '#fff',
    },
    gray: {
      main: '#939393'
    }
  },
  typography: {
    fontFamily: [
      'Noto Sans Thai',
    ].join(','),
    button: {
      textTransform: "none"
    }
  }
});

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

function App() {

  let iaam = window.iaam;
  const dispatch = useDispatch();

  const [isUserApproved, setIsUserApproved] = useState();

  useEffect(() => {
    iaam.initLoginFlow({
      client_id: process.env.REACT_APP_CLIENT_ID,
      iaamgw_host: `https://${process.env.REACT_APP_IAAM_GW_HOST}`,
      op_host: `https://${process.env.REACT_APP_OP_HOST}`,
      post_logout_uri: process.env.REACT_APP_POST_LOGOUT
    })
      .then((init) => {
        iaam.isLoggedIn((error, status) => {
          if (!status) {
            iaam.login()
          } else {
            iaam.getProfile((error, profile) => {
              if (error) {
                iaam.login()
              } else {
                dispatch(setIAAM(iaam));
                dispatch(setProfile(profile));
                setIsUserApproved(profile?.userApproved === "true");
              }
            })
          }
        })
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/*" element={isUserApproved === undefined ? <></> :
              isUserApproved ? <Main /> : <UserPermission />
            }></Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div >
  );
}

export default App;