import { createSlice } from '@reduxjs/toolkit'

export const iaamSlice = createSlice({
    name: 'iaamContext',
    initialState: {
        iaam: {},
        profile: {}
    },
    reducers: {
        setIAAM: (state, action) => {
            state.iaam = action.payload;
        },
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
    },
})

export const { setIAAM, setProfile } = iaamSlice.actions;

export const getIAAM = (state) => state.iaamContext.iaam;
export const getProfile = (state) => state.iaamContext.profile;

export default iaamSlice.reducer