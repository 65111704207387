import { Alert, Autocomplete, Box, Button, Chip, FormControl, IconButton, InputLabel, List, ListItem, MenuItem, OutlinedInput, Select, Stack, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getRegulatoryFile } from '../../../../../redux/regulatoryFileSlice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { setAttributeSelection } from '../../../../../redux/attributeSelectionSlice';
import { getDpeConfig } from '../../../../../redux/dpeConfigSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import { mask } from '../../../../../utils/privacy';

function RegulatoryRightSide(props) {

    const theme = useTheme();
    const {
        nodeSelected,
        open,
        setOpen,
        setNodes,
        nodes,
        setOpenCreateSubjectConstraint,
        setOpenSelectResource,
        setOpenAttributeSelection,
        setOpenCreateCondition
    } = props;
    const [label, setLabel] = useState("");
    const [description, setDescription] = useState("");
    const regulatoryFile = useSelector(getRegulatoryFile)
    const dispatch = useDispatch();

    //Subject
    const [subjectContraint, setSubjectContraint] = useState([]);

    //Object
    const [resourceLabel, setResourceLabel] = useState('-');
    const [attributeSelections, setAttributeSelections] = useState([]);
    // const dpeConfig = useSelector(getDpeConfig);
    const [conditions, setConditions] = useState([]);
    const [privacys, setPrivacys] = useState([]);
    const [dpeConfig, setDpeConfig] = useState();

    useEffect(() => {
        const dpeConfig = JSON.parse(localStorage.getItem('dpe-config'));
        if (dpeConfig) {
            setDpeConfig(dpeConfig);
        }
    }, []);

    const onChangeLabel = (value) => {
        setLabel(value)
        setNodes((nds) => nds.map((node) => {
            if (node.id === nodeSelected.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        general_properties: {
                            ...node.data.general_properties,
                            label: value,
                        }
                    },
                };
            }
            return node;
        }));
    };

    const onChangeDescription = (value) => {
        setDescription(value)
        setNodes((nds) => nds.map((node) => {
            if (node.id === nodeSelected.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        general_properties: {
                            ...node.data.general_properties,
                            description: value
                        }
                    }
                };
            }
            return node;
        }));
    };

    //Subject Function
    const handleOpenCreateSubjectConstraint = () => {
        setOpenCreateSubjectConstraint(true);
    };

    //Object Function
    const handleClick = (event) => {
        setOpenSelectResource(true);
        event.preventDefault();
        event.stopPropagation();
    };

    const handleOpenCreateCondition = () => {
        setOpenCreateCondition(true);
    };

    const handleOpenAttributeSelection = () => {
        setOpenAttributeSelection(true);
    };

    // const getResourceAttributeList = async () => {
    //     console.log("resource: " + resourceLabel);
    //     // const getAccessToken = JSON.parse(localStorage.getItem('iaam'));
    //     // const accessToken = getAccessToken.access_token;
    //     const iaam = window.iaam;
    //     const accessToken = await iaam.getAccessToken();
    //     let option = {
    //         method: 'GET',
    //         headers: {
    //             'Content-type': 'application/json',
    //             'Authorization': `bearer ${accessToken}`
    //         }
    //     }
    //     const response = await fetch(`${dpeConfig.resource_schema_endpoint}?resource=${resourceLabel}`, option);
    //     if (response.ok) {
    //         const result = await response.json();
    //         const new_result = result.map((attr) => {
    //             return {
    //                 // ...attr,
    //                 name: attr.column_name,
    //                 data_type: attr.data_type,
    //                 id: uuidv4(),
    //                 checked: true
    //             };
    //         });
    //         const attributes_selections = {
    //             resource: resourceLabel,
    //             attribute_selections: new_result
    //             // attribute_selection: new_result
    //         };
    //         dispatch(setAttributeSelection(attributes_selections));
    //         setAttributeSelections(new_result);
    //     } else {
    //         console.error(response);
    //     }
    // };

    const handleChangePrivacys = (id, event) => {
        const newPrivacys = privacys.map((privacy) => {
            if (privacy.privacy_id === id) {
                return { ...privacy, [event.target.name]: event.target.value };
            }
            return privacy;
        });
        setPrivacys(newPrivacys);
    };

    const handleChangeAttributePrivacys = (id, newInputValue) => {
        const newPrivacys = privacys.map((privacy) => {
            if (privacy.privacy_id === id) {
                return { ...privacy, attribute: newInputValue };
            }
            return privacy;
        });
        setPrivacys(newPrivacys);
    };

    const handleAddPrivacy = () => {
        const privacy = {
            privacy_id: uuidv4(),
            method: 'masking',
            attribute: "",
            position: 'left',
            symbol: '*',
            visible_length: 3
        }
        setPrivacys([...privacys, privacy])
    };

    const handleDeletePrivacy = (id) => {
        setPrivacys(privacys.filter((item) => item.privacy_id !== id));
    };

    const handleSetAttributeSelection = (attrs) => {
        const arr_attr = attrs.map(item => item.name);
        const arraysAreEqual = JSON.stringify(arr_attr) === JSON.stringify(nodeSelected?.data?.general_properties?.selection);
        if (!arraysAreEqual && nodeSelected.type === 'selection') {
            setNodes((nds) => nds.map((node) => {
                if (node.id === nodeSelected.id) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            general_properties: {
                                ...node.data.general_properties,
                                selection: arr_attr
                            }
                        }
                    };
                }
                return node;
            }));
        }
    };

    const getResourceAttributeList = async (resource_label, root_node) => {
        const iaam = window.iaam;
        const accessToken = await iaam.getAccessToken();
        let option = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `bearer ${accessToken}`
            }
        }
        const response = await fetch(`${dpeConfig.resource_schema_endpoint}?resource=${resource_label}`, option);
        if (response.ok) {
            const result = await response.json();
            const new_result = result.map((attr) => {
                return {
                    name: attr.column_name,
                    data_type: attr.data_type,
                    id: uuidv4(),
                    checked: true
                };
            });
            setAttributeSelections(new_result);
            const attribute_selections = {
                resource: root_node.data.general_properties.resource.resource_label,
                attribute_selections: new_result
            };
            dispatch(setAttributeSelection(attribute_selections));
            handleSetAttributeSelection(new_result);
            setNodes((nds) => nds.map((node) => {
                if (node.id === root_node.id) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            general_properties: {
                                ...node.data.general_properties,
                                attribute_selections: new_result
                            }
                        }
                    };
                }
                return node;
            }));
        } else {
            console.error(response);
        }
    };

    const renderSelectionComponent = () => {
        const root_node = nodes.find((node) => node.type === "root");
        const is_resource = root_node?.data?.general_properties?.resource?.resource_label;
        if (is_resource) {
            setResourceLabel(root_node.data.general_properties.resource.resource_label);
            if (root_node?.data?.general_properties?.attribute_selections) {
                setAttributeSelections(root_node.data.general_properties.attribute_selections);
                const attribute_selections = {
                    resource: root_node.data.general_properties.resource.resource_label,
                    attribute_selections: root_node.data.general_properties.attribute_selections
                };
                dispatch(setAttributeSelection(attribute_selections));
                handleSetAttributeSelection(root_node.data.general_properties.attribute_selections);
            } else {
                getResourceAttributeList(root_node.data.general_properties.resource.resource_label, root_node);
            }
        } else {
            setResourceLabel("-");
            setAttributeSelections([]);
            dispatch(setAttributeSelection({}));
            handleSetAttributeSelection([]);
        }
    };

    useEffect(() => {
        if (nodeSelected) {
            setLabel(nodeSelected.data.general_properties.label)
            setDescription(nodeSelected.data.general_properties.description)
            if (nodeSelected.type === 'subject' && nodeSelected.selected) {
                // console.log("node subject", nodeSelected)
                setSubjectContraint(nodeSelected?.data?.general_properties?.subject_constraint)
            }
            else if (nodeSelected.type === 'root' && nodeSelected.selected) {
                // console.log("node root", nodeSelected)
                if (nodeSelected?.data?.general_properties?.resource?.resource_label !== undefined) {
                    setResourceLabel(nodeSelected.data.general_properties.resource.resource_label);
                }
            } else if (nodeSelected.type === 'condition' && nodeSelected.selected) {
                // console.log("node condition", nodeSelected)
                if (nodeSelected.data.general_properties.conditions !== undefined) {
                    setConditions(nodeSelected.data.general_properties.conditions);
                }
            } else if (nodeSelected.type === 'selection' && nodeSelected.selected) {
                // console.log("node selection", nodeSelected)
                renderSelectionComponent();
            } else if (nodeSelected.type === "privacy" && nodeSelected.selected) {
                // console.log("node privacy", nodeSelected)
                renderSelectionComponent();
                if (nodeSelected.data.general_properties.privacys) {
                    setPrivacys(nodeSelected.data.general_properties.privacys)
                }
            }
        }
    }, [nodeSelected])

    useEffect(() => {
        if (regulatoryFile) {
            setOpen(false);
        }
    }, [regulatoryFile])

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                padding: '20px 15px',
            }}
        >
            {open ? (
                <>
                    <Typography>General Properties</Typography>
                    <TextField
                        required
                        id="node-label"
                        label="Label"
                        fullWidth
                        size='small'
                        margin="normal"
                        value={label}
                        onChange={(e) => onChangeLabel(e.target.value)}
                    // onBlur={handleDuplicate}
                    // error={subjectDiagramNameError}
                    // helperText={subjectDiagramNameTextError}
                    />
                    <TextField
                        id="description"
                        label="Description"
                        fullWidth
                        size='small'
                        multiline
                        rows={3}
                        margin="normal"
                        value={description}
                        onChange={(e) => onChangeDescription(e.target.value)}
                    />
                    {nodeSelected?.type === "subject" &&
                        <>
                            <Typography sx={{ marginTop: 2 }} variant='subtitle2'>Subject Constraint</Typography>
                            <div>
                                <List sx={{ padding: '0px' }}>
                                    {subjectContraint.map((item, index) => {
                                        return (
                                            <ListItem
                                                sx={{
                                                    border: '1px solid #ccc',
                                                    margin: '10px 0px',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                                key={index}
                                                secondaryAction={
                                                    <IconButton aria-label="more">
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <Chip
                                                    label={item}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </div>
                            <Button
                                sx={{
                                    marginTop: 2,
                                    border: '1px solid #ccc',
                                }}
                                variant="outlined"
                                fullWidth
                                onClick={() => handleOpenCreateSubjectConstraint()}
                            >
                                <AddIcon color='action' />
                            </Button>
                        </>
                    }
                    {nodeSelected?.type === "root" &&
                        <>
                            <FormControl sx={{ minWidth: 80, width: '100%', marginTop: 2 }}>
                                <InputLabel id="resource-label">Resource</InputLabel>
                                <Select
                                    labelId="resource-label"
                                    label="Resource"
                                    id="resource-label"
                                    fullWidth
                                    size="small"
                                    value={resourceLabel}
                                    input={<OutlinedInput label="Click Me" />}
                                    onMouseDown={handleClick} // ใช้ onMouseDown เพื่อให้การคลิกทำงานก่อน dropdown
                                    open={false} // ป้องกันการแสดง dropdown
                                    renderValue={() => resourceLabel}
                                    sx={{ color: '#000' }}
                                >
                                </Select>
                            </FormControl>
                        </>
                    }
                    {nodeSelected?.type === "condition" &&
                        <>
                            <Typography sx={{ marginTop: 2 }} variant='subtitle2'>Condition</Typography>
                            <div>
                                <List sx={{ padding: '0px' }}>
                                    {conditions.map((item, index) => {
                                        return (
                                            <ListItem
                                                sx={{
                                                    border: '1px solid #ccc',
                                                    margin: '10px 0px',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                                key={index}
                                                secondaryAction={
                                                    <IconButton aria-label="more">
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <Chip
                                                    label={item.display}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </div>
                            <Button
                                sx={{
                                    marginTop: 2,
                                    border: '1px solid #ccc',
                                }}
                                variant="outlined"
                                fullWidth
                                onClick={() => handleOpenCreateCondition()}
                            >
                                <AddIcon color='action' />
                            </Button>
                        </>
                    }
                    {(nodeSelected?.type === "selection" && attributeSelections) &&
                        <>
                            <Typography mt={2} mb={1} variant='subtitle2'>Attribute Selection</Typography>
                            <div>
                                <Stack
                                    sx={{
                                        width: '100%',
                                        border: '1px solid #ccc',
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                        paddingLeft: '10px',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}
                                    direction='row'
                                    justifyContent="space-between"
                                >
                                    <Box display="flex">
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            color="primary"
                                            noWrap
                                            sx={{
                                                marginRight: '5px',
                                            }}
                                        >
                                            Resource:
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            color="black"
                                            noWrap
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '155px'
                                            }}
                                        >
                                            {resourceLabel}
                                        </Typography>
                                    </Box>
                                    <div>
                                        <IconButton onClick={handleOpenAttributeSelection}>
                                            <EditNoteIcon />
                                        </IconButton>
                                    </div>
                                </Stack>
                                <div
                                    style={{
                                        border: '1px solid #ccc',
                                        borderBottomLeftRadius: '5px',
                                        borderBottomRightRadius: '5px',
                                        maxHeight: 'calc(100vh - 390px)',
                                        overflow: 'auto'
                                    }}
                                >
                                    <List sx={{ padding: '0px' }}>
                                        {attributeSelections.map((item, index) => {
                                            if (item.checked) {
                                                return (
                                                    <ListItem
                                                        sx={{
                                                            padding: '5px 0px 5px 10px',
                                                        }}
                                                        key={index}
                                                    >
                                                        {item.name}
                                                    </ListItem>
                                                )
                                            }
                                        })}
                                    </List>
                                </div>
                            </div>
                        </>
                    }
                    {(nodeSelected?.type === "privacy" && attributeSelections) &&
                        <>
                            <Typography mt={2} variant='subtitle2'>Privacy</Typography>
                            <div>
                                <List sx={{
                                    padding: '0px',
                                    maxHeight: 'calc(100vh - 390px)',
                                    overflow: 'auto'
                                }}>
                                    {privacys.map((item, index) => {
                                        return (
                                            <Box key={index} sx={{ marginTop: 2, marginBottom: 1 }}>
                                                <Stack
                                                    sx={{
                                                        width: '100%',
                                                        border: '1px solid #ccc',
                                                        borderTopLeftRadius: '5px',
                                                        borderTopRightRadius: '5px',
                                                        px: '14px',
                                                        py: '6px',
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                    }}
                                                    direction='row'
                                                    justifyContent="space-between"
                                                >
                                                    <Box className="flex flex-row items-center gap-2">
                                                        <Typography variant="caption" color="primary">Method: </Typography>
                                                        <FormControl variant="standard" size='small' sx={{ fontSize: 14, mt: '5px' }}>
                                                            <Select
                                                                labelId="select-method"
                                                                id="method"
                                                                name="method"
                                                                value={item.method ? item.method : 'masking'}
                                                                sx={{ fontSize: 12 }}
                                                                label="method"
                                                                onChange={(event) => handleChangePrivacys(item.privacy_id, event)}
                                                            >
                                                                <MenuItem value='masking'>Masking</MenuItem>
                                                                <MenuItem value='encrypt'>Encrypt</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                    <IconButton sx={{ width: 24, height: 24 }} onClick={() => handleDeletePrivacy(item.privacy_id)}>
                                                        <DeleteIcon sx={{ width: 22, height: 22 }} className='text-gray-400 hover:text-red-800' />
                                                    </IconButton>

                                                </Stack>
                                                <div
                                                    style={{
                                                        border: '1px solid #ccc',
                                                        borderBottomLeftRadius: '5px',
                                                        borderBottomRightRadius: '5px',
                                                        padding: '14px'
                                                    }}
                                                >
                                                    <Box>
                                                        <Autocomplete
                                                            name="attribute"
                                                            fullWidth
                                                            size='small'
                                                            options={attributeSelections}
                                                            // getOptionLabel={(option) => option.name}
                                                            // inputValue={item.attribute}
                                                            // onInputChange={(event, newInputValue) => {
                                                            //     handleChangeAttributePrivacys(item.privacy_id, newInputValue)
                                                            // }}
                                                            value={item.attribute}
                                                            onChange={(event, newValue) => {
                                                                handleChangeAttributePrivacys(item.privacy_id, newValue);
                                                            }}
                                                            getOptionLabel={(option) => option.name}
                                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                                            id="attribute-select"
                                                            renderInput={(params) => <TextField {...params} label="Attribute" />}
                                                        />
                                                    </Box>
                                                    <Box sx={{ marginTop: 2 }}>
                                                        <TextField
                                                            type="number"
                                                            name="visible_length"
                                                            label="Visible length"
                                                            size='small'
                                                            value={item.visible_length ? item.visible_length : "3"}
                                                            onChange={(event) => handleChangePrivacys(item.privacy_id, event)}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </Box>
                                                    <Stack direction='row' spacing={1} mt={2}>
                                                        <FormControl fullWidth size='small'>
                                                            <InputLabel id="select-position">Symbol</InputLabel>
                                                            <Select
                                                                labelId="select-symbol"
                                                                id="select-symbol"
                                                                name="symbol"
                                                                value={item.symbol ? item.symbol : "*"}
                                                                label="Symbol"
                                                                onChange={(event) => handleChangePrivacys(item.privacy_id, event)}
                                                            >
                                                                <MenuItem value='*'>*</MenuItem>
                                                                <MenuItem value='#'>#</MenuItem>
                                                                <MenuItem value='$'>$</MenuItem>
                                                                <MenuItem value='@'>@</MenuItem>
                                                                <MenuItem value='?'>?</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl fullWidth size='small'>
                                                            <InputLabel id="select-position">Position</InputLabel>
                                                            <Select
                                                                labelId="select-position"
                                                                id="select-position"
                                                                name="position"
                                                                value={item.position ? item.position : "left"}
                                                                label="Position"
                                                                onChange={(event) => handleChangePrivacys(item.privacy_id, event)}
                                                            >
                                                                <MenuItem value='left'>Left</MenuItem>
                                                                <MenuItem value='right'>Right</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                    <Alert icon={<PreviewIcon fontSize="inherit" />} className='mt-3' severity="info">
                                                        {mask("Preview Message", Number(item.visible_length), item.symbol, item.position)}
                                                    </Alert>
                                                </div>
                                            </Box>
                                        )
                                    })}
                                </List>
                            </div>
                            <Button
                                sx={{
                                    marginTop: 2,
                                    border: '1px solid #ccc',
                                }}
                                variant="outlined"
                                fullWidth
                                onClick={() => handleAddPrivacy()}
                            >
                                <AddIcon color='action' />
                            </Button>
                        </>
                    }
                </>
            ) : (
                <>
                    <Typography>Regulatory Workspace</Typography>
                    <Typography mt={3} variant="caption" display="block" color="grey.500">Regulatory Name</Typography>
                    <Typography>{regulatoryFile?.regulatory_diagram_name}</Typography>
                    <Typography mt={3} variant="caption" display="block" color="grey.500">Description</Typography>
                    <Typography>{regulatoryFile?.description === "" ? "-" : regulatoryFile?.description}</Typography>
                </>
            )}
        </div >
    )
}

export default RegulatoryRightSide