import { createSlice } from '@reduxjs/toolkit'

export const regulatoryFileSlice = createSlice({
    name: 'regulatoryFile',
    initialState: {
        result: undefined,
    },
    reducers: {
        setRegulatoryFile: (state, action) => {
            state.result = action.payload;
        },
        clearRegulatoryFile: (state) => {
            state.result = undefined;
        }
    },
})

export const { setRegulatoryFile, clearRegulatoryFile } = regulatoryFileSlice.actions;

export const getRegulatoryFile = (state) => state.regulatoryFile.result;

export default regulatoryFileSlice.reducer