import { Box, Button, Grid, IconButton, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import CardAdd from '../../components/CardAdd';
import PolylineIcon from '@mui/icons-material/Polyline';
import GroupIcon from '@mui/icons-material/Group';
import { getProject } from '../../redux/projectSlice';
import { useDispatch, useSelector } from 'react-redux';
import { clearSubjectFile } from '../../redux/subjectFileSlice';
import { clearObjectFile } from '../../redux/objectFileSlice';
import { setOpenRightSide } from '../../redux/openRightSideSlice';
import { clearRegulatoryFile } from '../../redux/regulatoryFileSlice';

function ProjectWorkSpace() {

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const project = useSelector(getProject);
    // const project = JSON.parse(localStorage.getItem('project'));
    const [subjectCount, setSubjectCount] = useState(0)
    const [objectCount, setObjectCount] = useState(0)
    const [regulatoryCount, setRegulatoryCount] = useState(0)
    const [project, setProject] = useState();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const getDRGCount = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/misc/drg-count/${project.project.project_id}`);
        if (response.status === 200) {
            const result = await response.json();
            setSubjectCount(result.subject_diagram_count)
            setObjectCount(result.object_diagram_count)
            setRegulatoryCount(result.regulatory_diagram_count)
        } else {
            console.error(response.status);
        }
    };

    const handleClearSelectedFile = () => {
        dispatch(clearSubjectFile());
        dispatch(clearObjectFile());
        dispatch(clearRegulatoryFile());
        dispatch(setOpenRightSide(false));
    };

    useEffect(() => {
        if (project !== undefined) {
            getDRGCount();
        } 
    }, [project])

    return (
        <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 overflow-auto w-full">
            <div className='pt-10'>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <IconButton sx={{ marginRight: 0.5, marginLeft: -1 }} aria-label="home" color="primary" component={Link} to="/editor">
                        <HomeIcon sx={{ width: 30, height: 30 }} />
                    </IconButton>
                    <Typography component='span' variant='h4' fontWeight='bold'>{project?.project?.project_name}</Typography>
                </Box>
                <Box sx={{ marginTop: 5 }}>
                    <Typography component='span' variant='h5'>Policy</Typography>
                </Box>
                <Box sx={{ marginTop: 3 }}>
                    <CardAdd />
                </Box>
                <Box sx={{ marginTop: 10 }}>
                    <Typography component='span' variant='h5'>Data Regulatory Graph</Typography>
                </Box>
                <Box sx={{
                    marginTop: 3,
                    padding: 2,
                    width: '100%',
                    borderRadius: 1,
                    backgroundColor: '#FFFFFF',
                    flexGrow: 1,
                    boxShadow: 2
                }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ color: 'white', p: 1 }}>
                                <Typography component='span' variant='h7' className='font-normal text-lg'>Manage the Subject, Object and Regulatory</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ color: 'white', p: 1 }}>
                                <Button
                                    size='medium'
                                    sx={{ textTransform: 'none' }}
                                    variant="contained"
                                    component={Link}
                                    to="/drg/subject"
                                    onClick={() => handleClearSelectedFile()}
                                >DRG Management</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ color: 'white', p: 1, paddingTop: 5 }}>
                                <Stack direction='row' spacing={2}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                        <GroupIcon color="primary" sx={{ width: 30, height: 30, marginRight: 1 }} />
                                        <Typography component='span' variant='subtitle1' color={theme.palette.gray.main}>{subjectCount} Subject(s)</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                        <SvgIcon sx={{ width: 22, height: 22, marginRight: 1 }}>
                                            <svg version="1.1" viewBox="0 0 2048 2048" width="1280" height="1280" fill="#1976d2" xmlns="http://www.w3.org/2000/svg">
                                                <path transform="translate(1181,50)" d="m0 0h76l54 1 21 2 13 4 14 7 14 10 13 13 10 14 8 14 5 14 3 15 1 15v535l-1 35-3 20-4 12-9 17-7 10-9 11-11 10-19 10-16 5-20 3-43 2-147 1-2 1-1 260v514l55 1h234v-33l1-51 2-23 5-16 6-14 9-13 11-13 9-8 14-10 17-8 10-3 8-1 30-1h340l30 1 15 2 17 6 14 8 11 9 13 13 10 15 8 16 4 12 2 14 1 22v286l-1 73-2 23-4 14-8 16-7 11-11 13-13 11-12 7-16 6-20 4-12 1-34 1-163 1h-144l-37-1-19-2-13-4-17-9-12-9-11-10-11-14-9-16-6-18-3-19-1-20v-73h-776v92l-3 21-5 13-8 16-8 12-13 14-13 10-17 8-15 4-12 2-13 1-40 1-173 1h-125l-39-1-19-2-15-5-16-9-14-11-11-11-10-14-8-15-5-16-2-10-1-10-1-24v-339l2-26 3-14 5-13 8-15 11-14 12-12 16-11 16-8 10-3 7-1 55-1h305l38 1 18 2 19 7 12 7 10 8 10 9 10 13 9 16 6 15 3 15 1 11v90h129l160 1 1-778-134 1-49-1-22-2-15-4-16-8-10-7-11-9-8-8-10-14-9-17-5-17-2-12-1-14v-567l4-20 6-16 10-18 13-15 8-7 10-7 19-10 12-4 21-2h159z" />
                                            </svg>
                                        </SvgIcon>
                                        <Typography component='span' variant='subtitle1' color={theme.palette.gray.main}>{objectCount} Object(s)</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                        <PolylineIcon color="primary" sx={{ marginRight: 1 }} />
                                        <Typography component='span' variant='subtitle1' color={theme.palette.gray.main}>{regulatoryCount} Regulatory(s)</Typography>
                                    </Box>
                                </Stack>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </div>
        </section>
    )
}

export default ProjectWorkSpace