import React, { useEffect, useState } from 'react'
import SearchFiles from '../../../../../components/SearchFiles'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, styled, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { getProject } from '../../../../../redux/projectSlice';
import GroupIcon from '@mui/icons-material/Group';
import ShapeLineIcon from '@mui/icons-material/ShapeLine';

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0.15)',
}));

function RegulatoryComponents() {

    const theme = useTheme();
    // const project = useSelector(getProject);
    // const project = JSON.parse(localStorage.getItem('project'));
    const [nodeTypes, setNodeTypes] = useState([]);
    const [filteredItems, setFilteredItems] = useState(nodeTypes);
    const [subjectComponents, setSubjectComponents] = useState([]);
    const [objectComponents, setObjectComponents] = useState([]);
    const [project, setProject] = useState();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const onDragStart = (event, nodeData) => {
        event.dataTransfer.setData('application/reactflow', JSON.stringify(nodeData));
        event.dataTransfer.effectAllowed = 'move';
    };

    const handleSearch = (term) => {
        const filtered = nodeTypes.filter(item =>
            item.type_name.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredItems(filtered);
    };

    const getListSubjects = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/subject-diagram/get-subjects/${project.project.project_id}`);
        if (response.ok) {
            const result = await response.json();
            setSubjectComponents(result);
        } else {
            console.error(response.status);
        }
    };

    const getListObjects = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/object-diagram/get-objects/${project.project.project_id}`);
        if (response.ok) {
            const result = await response.json();
            setObjectComponents(result);
        } else {
            console.error(response.status);
        }
    };

    useEffect(() => {
        if (project) {
            getListSubjects();
            getListObjects();
        }
    }, [project])

    return (
        <aside>
            <SearchFiles onSearch={handleSearch} />
            <div>
                <Accordion square elevation={0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="subject-content"
                        id="subject-header"
                    >
                        <Typography variant='subtitle2'>Subject Components</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: 0 }}>
                        <List sx={{ padding: 0 }}>
                            {subjectComponents.map((item, index) => {
                                return (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        sx={{
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            padding: 1,
                                            marginBottom: 1
                                        }}
                                        onDragStart={(event) => onDragStart(event, {
                                            type: 'subject',
                                            diagram_id: item.subject_diagram_id
                                        })}
                                        draggable
                                    >
                                        <GroupIcon color="primary" sx={{ marginRight: 1, width: 24, height: 24 }} />
                                        <Typography variant="body2">{item.subject_diagram_name}</Typography>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion square elevation={0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="object-content"
                        id="object-header"
                    >
                        <Typography variant='subtitle2'>Object Components</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: 0 }}>
                        <List sx={{ padding: 0 }}>
                            {objectComponents.map((item, index) => {
                                return (
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        sx={{
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            padding: 1,
                                            marginBottom: 1
                                        }}
                                        onDragStart={(event) => onDragStart(event, {
                                            type: 'object',
                                            diagram_id: item.object_diagram_id
                                        })}
                                        draggable
                                    >
                                        <ShapeLineIcon color="primary" sx={{ marginRight: 1, width: 18, height: 18 }} />
                                        <Typography variant="body2">{item.object_diagram_name}</Typography>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </div>
        </aside >
    )
}

export default RegulatoryComponents